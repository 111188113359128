import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchTrackerRequest: ['data'],
  fetchTrackerSuccess: ['data'],
  fetchTrackerByClientRequest: ['data'],
  fetchTrackerIdSuccess: ['data'],
  fetchTrackerByClientSuccess: ['data'],
  fetchTrackerFailure: [],
  updateTrackerRequest: ['data'],
  createTrackerRequest: ['data'],
  fetchFilter: ['plate', 'active'],
});

const INITIAL_STATE = {
  trackers: [],
  trackersImutable: [],
  tracker: {},
  trackerClient: [],
  loading: false,
};

const fetchTrackerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
const fetchTrackerByClientRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchTrackerSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  trackers: data,
  trackersImutable: data,
  loading: false,
});

const fetchTrackerIdSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  tracker: actions.data,
  loading: false,
});

const fetchTrackerByClientSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    trackerClient: actions.data,
    loading: false,
  };
};

const fetchTrackerFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    trackers: [],
  };
};

const updateTrackerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const createTrackerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchfilters = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    trackers: state.trackersImutable
      .filter(tracker => {
        if (tracker.plate.indexOf(actions.plate) !== -1) {
          return tracker;
        }
      })
      .filter(tracker => {
        return tracker.active === actions.active;
      }),
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_TRACKER_REQUEST]: fetchTrackerRequest,
  [Types.FETCH_TRACKER_BY_CLIENT_REQUEST]: fetchTrackerByClientRequest,
  [Types.FETCH_TRACKER_SUCCESS]: fetchTrackerSuccess,
  [Types.FETCH_TRACKER_SUCCESS]: fetchTrackerSuccess,
  [Types.FETCH_TRACKER_ID_SUCCESS]: fetchTrackerIdSuccess,
  [Types.FETCH_TRACKER_BY_CLIENT_SUCCESS]: fetchTrackerByClientSuccess,
  [Types.FETCH_TRACKER_FAILURE]: fetchTrackerFailure,
  [Types.UPDATE_TRACKER_REQUEST]: updateTrackerRequest,
  [Types.CREATE_TRACKER_REQUEST]: createTrackerRequest,
  [Types.FETCH_FILTER]: fetchfilters,
});
