import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Creators as ClientActions } from '../../store/ducks/clientSauce';
import SimpleSelect from '../selects/simpleSelect';

const Container = styled.span`
  min-width: 200px;
`;

function SelectClientField({ changeClientValue, error }) {
  const loading = useSelector(state => state.clientState.loading);
  const clients = useSelector(state =>
    state.clientState.clients
      .map(client => ({
        label: client.name,
        value: client.id,
      }))
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ClientActions.fetchClientRequest());
  }, [dispatch]);

  return (
    <Container>
      <SimpleSelect
        options={clients}
        loading={loading}
        onChange={option => changeClientValue(option)}
        placeholder="Selecione os clientes"
        error={error}
      />
    </Container>
  );
}

SelectClientField.propTypes = {
  changeClientValue: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

SelectClientField.defaultProps = {
  error: false,
};

export default SelectClientField;
