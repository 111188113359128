import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchPermissionRequest: ['data'],
  fetchPermissionSuccess: ['data'],
  fetchPermissionFailure: [],
  updatePermissionRequest: ['data'],
  updatePermissionSuccess: [],
});

const INITIAL_STATE = {
  permissions: [],
  loading: false,
};

const fetchPermissionRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchPermissionSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  permissions: data,
  loading: false,
});

const fetchPermissionFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    permissions: [],
  };
};

const updatePermissionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
const updatePermissionSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_PERMISSION_REQUEST]: fetchPermissionRequest,
  [Types.FETCH_PERMISSION_SUCCESS]: fetchPermissionSuccess,
  [Types.FETCH_PERMISSION_FAILURE]: fetchPermissionFailure,
  [Types.UPDATE_PERMISSION_REQUEST]: updatePermissionRequest,
  [Types.UPDATE_PERMISSION_SUCCESS]: updatePermissionSuccess,
});
