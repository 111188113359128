import styled from 'styled-components';

const Container = styled.form`
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-gap: 10px;
  margin: 10px;
`;

export default Container;
