import PropTypes from 'prop-types';
import React from 'react';
import { MdUpdate } from 'react-icons/md';

import Car from '../../assets/home/car.svg';
import Client from '../../assets/home/client.svg';
import Device from '../../assets/home/device.svg';
import Message from '../../assets/home/message.svg';
import Permission from '../../assets/home/permission.svg';
import PermissionDrivers from '../../assets/home/permissionDrivers.svg';
import Report from '../../assets/home/report.svg';
import Type from '../../assets/home/type.svg';
import User from '../../assets/home/user.svg';
import { Container, Card, ImageContainer, DetailContainer } from './styles';

function Home({ history }) {
  function redirectRoute(route) {
    history.push(route);
  }
  // const [widthPage, setWidth] = useState(window.innerWidth);

  // window.onresize = () => {
  //   setWidth(window.innerWidth);
  // };
  return (
    <Container>
      <Card gridName="car" onClick={() => redirectRoute('/tracker')}>
        <ImageContainer car>
          <img src={Car} alt="Carro" />
        </ImageContainer>
        <DetailContainer>Veículos</DetailContainer>
      </Card>
      <Card gridName="user" onClick={() => redirectRoute('/user')}>
        <ImageContainer>
          <img src={User} alt="Usuário" />
        </ImageContainer>
        <DetailContainer>Usuário</DetailContainer>
      </Card>
      <Card gridName="client" onClick={() => redirectRoute('/client')}>
        <ImageContainer>
          <img src={Client} alt="Cliente" />
        </ImageContainer>
        <DetailContainer>Cliente</DetailContainer>
      </Card>
      <Card gridName="device" onClick={() => redirectRoute('/device')}>
        <ImageContainer>
          <img src={Device} alt="Dispositivo" />
        </ImageContainer>
        <DetailContainer>Dispositivo</DetailContainer>
      </Card>
      <Card gridName="permission" onClick={() => redirectRoute('/permission')}>
        <ImageContainer>
          <img src={Permission} alt="Permissão" />
        </ImageContainer>
        <DetailContainer>Permissão</DetailContainer>
      </Card>
      <Card
        gridName="permissionLogbook"
        onClick={() => redirectRoute('/logbook')}
      >
        <ImageContainer>
          <img src={PermissionDrivers} alt="Permissões Diário de Bordo" />
        </ImageContainer>
        <DetailContainer>Permissões Diário de Bordo</DetailContainer>
      </Card>
      <Card gridName="type" onClick={() => redirectRoute('/type')}>
        <ImageContainer>
          <img src={Type} alt="Tipo de icone" />
        </ImageContainer>
        <DetailContainer>Tipo</DetailContainer>
      </Card>

      <Card gridName="messages" onClick={() => redirectRoute('/message')}>
        <ImageContainer>
          <img src={Message} alt="Mensagem" />
        </ImageContainer>
        <DetailContainer>Mensagem</DetailContainer>
      </Card>

      <Card gridName="report" onClick={() => redirectRoute('/report')}>
        <ImageContainer>
          <img src={Report} alt="Relatório" />
        </ImageContainer>
        <DetailContainer>Relatório</DetailContainer>
      </Card>
      <Card gridName="news" onClick={() => redirectRoute('/news')}>
        <ImageContainer>
          <MdUpdate size={70} />
        </ImageContainer>
        <DetailContainer>Atualizações</DetailContainer>
      </Card>
    </Container>
  );
}

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Home;
