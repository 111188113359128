import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    html, border-style, #root {
        height: 100vh
    }

    body {
        font: 14px 'Roboto', sans-serif;
        background: #F9FAFD;
        color: #333;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased !important;
    }

    a {
        text-decoration: none;
        color: #333;
    }
`;
