import styled, { css } from 'styled-components';

export const Header = styled.div`
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  > div {
    font-size: 20px;
    font-weight: 600;
  }
  @media screen and (max-width: 700px) {
    ${props =>
      props.permission &&
      css`
        flex-direction: column;
        align-items: self-start;
        padding: 0px 10px;
        justify-content: space-evenly;
      `}
  }
  
`;

export const Button = styled.button`
  background: rgba(0,0,0,0.2);
  padding: 10px;
  border: none;
  border-radius: 15px;
  margin: 5px;
  margin-left: 0;

  :hover {
    background: rgba(0,0,0,0.4);
  }
`;



export const SelectField = styled.span`
  width: 200px;
`;

export const ContainerUser = styled.div`
  width: 100%;
  margin: 5px 0;
`;
