import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingDefault from '../../components/animations/loadingAnimationDefault';
import Box from '../../components/Box';
import ContainerForm from '../../components/containers/containerForm';
import SelectClientField from '../../components/fields/selectClientField';
import SelectField from '../../components/fields/selectField';
import SimpleField from '../../components/fields/simpleField';
import HeaderCardForm from '../../components/headerCardForm';
import { Creators as userActions } from '../../store/ducks/userSauce';
import { CheckBox } from './styles';
import {
  CheckBoxInput,
  SimpleInput,
  MaskedInput,
} from '../../components/inputs';

function User({ match, history }) {
  const user = useSelector(state => state.userState.user);
  const loading = useSelector(state => state.userState.loading);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      cpf: '',
      active: '',
      phone: '',
      client_id: '',
      role: '',
      client: { name: '' },
      passenger: false,
    },
    validate: values => {
      const err = {};
      const message = 'Campo obrigatório!';
      if (!values.name) err.name = message;
      if (!values.client_id) err.client_id = message;
      if (!values.email) err.email = message;
      if (!values.cpf) err.cpf = message;
      if (!values.phone) err.phone = message;
      if (!values.role) err.role = message;

      return err;
    },
    onSubmit: data => {
      if (data.id) {
        dispatch(
          userActions.updateUserRequest({ ...data, password: undefined })
        );
      } else {
        dispatch(userActions.createUserRequest(data));
      }
    },
  });

  useEffect(() => {
    if (match.params.id) {
      dispatch(userActions.fetchUserRequest(`/${match.params.id}`));
    } else {
      formik.setValues({ ...formik.values, role: 'basic', active: true });
    }
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (!_.isEmpty(user)) {
      formik.setValues({ ...user });
    }
  }, [user]);

  function getRole(role) {
    if (role === 'basic') return 'Básico';
    if (role === 'master') return 'Administrador';
    return '';
  }
  return (
    <ContainerForm onSubmit={formik.handleSubmit}>
      <HeaderCardForm
        title="Formulário de usuário"
        route="/user"
        history={history}
        loading={loading}
      />
      {loading ? (
        <LoadingDefault />
      ) : (
        <Box>
          <SimpleField>
            <div>Nome</div>
            <SimpleInput
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
            />
          </SimpleField>
          <SimpleField>
            <div>E-mail</div>
            <SimpleInput
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
            />
          </SimpleField>
          <SimpleField>
            <div>CPF</div>
            <MaskedInput
              mask="999.999.999-99"
              name="cpf"
              onChange={formik.handleChange}
              value={formik.values.cpf}
              error={formik.touched.cpf && formik.errors.cpf}
            />
          </SimpleField>
          <SimpleField>
            <div>Telefone</div>
            <MaskedInput
              mask="(99)9 9999-9999"
              name="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
              error={formik.touched.phone && formik.errors.phone}
            />
          </SimpleField>
          <SelectClientField
            changeClientValue={option =>
              formik.setFieldValue('client_id', option.value)
            }
            defaultValue={{
              label: formik.values.client.name,
              value: formik.values.cliet_id,
            }}
            error={!!(formik.touched.client_id && formik.errors.client_id)}
          />
          {formik.values.role && (
            <SelectField
              title="Função"
              placeholder="Selecione a função"
              options={[
                { label: 'Administrador', value: 'master' },
                { label: 'Básico', value: 'basic' },
              ]}
              defaultValue={{
                label: getRole(formik.values.role),
                value: formik.values.role,
              }}
              changeValueSelect={option =>
                formik.setFieldValue('role', option.value)
              }
            />
          )}
          {formik.values.active !== '' && (
            <CheckBoxInput
              id="active"
              label="Ativo"
              isChecked={formik.values.active}
              changeValue={value => formik.setFieldValue('active', value)}
              onClick={() =>
                formik.setFieldValue('active', !formik.values.active)
              }
            />
          )}
          <CheckBoxInput
            id="passenger"
            label="Passageiro / Responsável"
            isChecked={formik.values.passenger}
            changeValue={value => formik.setFieldValue('passenger', value)}
            onClick={() =>
              formik.setFieldValue('passenger', !formik.values.passenger)
            }
          />
        </Box>
      )}
    </ContainerForm>
  );
}
// onChange={({ target: { checked } }) => changeValue(checked)}
// !formik.values.active
User.propTypes = {
  history: PropTypes.objectOf.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default User;
