import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import format from 'date-fns/format';

import Box from '../../components/BoxFilter';
import Card from '../../components/cards/cardListUser';
import SimpleInput from '../../components/inputs/simpleInput';
import TableComponent from '../../components/Table';
import api from '../../services/api';
import { ContainerTable } from './styles';
import history from '../../routes/history';

function Table({ height }) {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getNews() {
    try {
      let { data: news } = await api.fetchNews();
      news = news
        .map((n) => ({...n, date: new Date(`${n.date} 00:00:00`)}))
        .map((n) => ({...n, date: format(n.date, 'dd/MM/yyyy' )}))
        setLoading(false)      
      setNews(news || []);
    } catch (error) {
      console.error({error})
    }
  }

  useEffect(() => {
    setLoading(true)
    getNews();
  }, []);

  const [widthPage, setWidth] = useState(window.innerWidth);

  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <ContainerTable>
      {widthPage <= 700 && <Box columns={1}></Box>}
      <div style={{ height, overflow: 'scroll' }}>
        {widthPage <= 700 ? (
          <Card
            data={news}
            loading={loading}
            changeValue={data => history.push(`/news/form/${data.id}`)}
          />
        ) : (
          <Box>
            <TableComponent
              data={news.map(item => ({
                ...item,
                public: item.public ? 'Publicado' : ' ',
              }))}
              fileTitle="Relatório de novidades do sistema"
              name="novidades do sistema"
              labels={['Título', 'Data', 'Publicado']}
              headers={['title', 'date', 'public']}
              getData={data => history.push(`/news/form/${data.id}`)}
              loading={loading}
            />
          </Box>
        )}
      </div>
    </ContainerTable>
  );
}

Table.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  height: PropTypes.string,
};

Table.defaultProps = {
  height: '200px',
};

export default Table;
