import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #3e3838;
  color: #0991d1;
  grid-area: nav;
  padding: 0 5px;
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  }
`;

export const ButtonFilter = styled.button`
  display: flex;
  padding: 5px;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
  color: #f2f1f2;
  text-decoration: none;
  background: transparent;
  border: none;
  font-size: 15px;
  font-weight: 600;
  @media (max-width: 550px) {
  }
`;

export const SimpleInput = styled.input`
  display: flex;
  height: 40px;
  width: 100%;
  border: none;
  text-align: center;
  margin-bottom: 0px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
`;

export const ButtonsContainer = styled.div``;

export const TitleContainer = styled.div`
  > button {
    display: flex;
    color: #0991d1;
    text-decoration: none;
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 5px;
  font-size: 20px;
  font-weight: 600;
`;

export const Description = styled.div`
  font-size: 12px;
`;

export const ButtonLogo = styled.button`
  background: transparent;
  border: none;
  padding: 5px 5px;
`;

export const Logo = styled.img`
  align-items: center;
  display: flex;
  background: transparent;
  padding: 0px 10px 0px;
  height: 30px;
`;

export const ClientIcon = styled.img`
  display: flex;
  width: 65px;
  height: 30px;
  padding-left: 10px;
  background: transparent;
`;

export const ClientButton = styled.button`
  display: flex;
  height: 30px;
  padding-right: 10px;
  align-items: center;
  border: none;
  border-radius: 5px;
  background: #4e565c;
  :disabled {
    cursor: not-allowed;
  }
`;

export const ModalContainer = styled.form`
  height: 100%;
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  padding: 10px;
  overflow: 0px;
  overflow: scroll;
`;

export const Descripition = styled.div`
  ${props =>
    props.output &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 50px 5px;
    `}
`;
