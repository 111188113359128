import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-template-columns: 50px 1fr;
  grid-template-areas: 'nav nav' 'side main';

  @media screen and (max-width: 700px) {
    grid-template-rows: 40px 1fr 50px;
    grid-template-columns: 1fr;
    grid-template-areas: 'nav' 'main' 'side';
  }
`;
