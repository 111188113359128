import 'react-toastify/dist/ReactToastify.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';

import React from 'react';
import PWAprompt from 'react-ios-pwa-prompt';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';
import store from './store';
import { Container } from './styles';
import GlobalStyle from './styles/global';

export default function App() {
  return (
    <Provider store={store}>
      <ToastContainer style={{ zIndex: '999999' }} />
      <GlobalStyle />
      <PWAprompt />
      <Container>
        <Routes />
      </Container>
    </Provider>
  );
}
