import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '../../components/Box';
import Container from '../../components/containers/containerSimple';
import MultiSelectClient from '../../components/filters/multiSelectClient';
import { Creators as driverActions } from '../../store/ducks/driverSauce';
import { Creators as userActions } from '../../store/ducks/userSauce';
import DriverSide from './driverSide';
import { Header } from './styles';
import UserSide from './userSide';

const admUsers = [24 /* GEISIBEL */, 338 /* JEISIANE */, 11 /* LUCAS */];

export default function PermissionLogbook() {
  const token = useSelector(state => state.authState.token);
  const dispatch = useDispatch();
  const [clientId, setClientId] = useState(null);
  async function changeValues(options) {
    if (_.isEmpty(options)) {
      dispatch(userActions.fetchUserClientClear());
      dispatch(driverActions.fetchDriverClientClear());
    } else {
      const clientsId = options.map(item => item.value).pop();
      setClientId(clientsId[0]);
      dispatch(driverActions.fetchDriverByClientRequest([clientsId]));
      dispatch(userActions.fetchUserByClientRequest(clientsId));
    }
  }
  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  const [hasPermission, setHasPermission] = useState(false);

  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useEffect(() => {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }, [hasPermission]);

  useLayoutEffect(() => {
    const currentUser = jwtDecode(token);
    const userHasPermission = currentUser.cli === 2; /* GEORASTREAMENTO */
    // &&
    // admUsers.includes(currentUser.sub);
    setHasPermission(userHasPermission);

    setHeight(0);
    dimension();
  }, []);

  return (
    <Container permission>
      <Header permission>
        <div>Permissões Diário de Bordo</div>
        {hasPermission && (
          <MultiSelectClient
            changeClientValue={option => changeValues(option)}
          />
        )}
      </Header>
      <Box permission>
        {hasPermission && (
          <>
            <div style={{ height: '100%' }} ref={targetRef}>
              <UserSide height={height} />
            </div>
            <div style={{ height: '100%' }} ref={targetRef}>
              <DriverSide height={height} clientId={clientId} />
            </div>
          </>
        )}
        {!hasPermission && (
          <p
            style={{
              paddingLeft: '30px',
              paddingTop: '10px',
              fontSize: 'large',
            }}
          >
            Somente os usuários listados abaixo tem permissão para utilizar esta
            tela:
            <ul style={{ paddingLeft: '20px', paddingTop: '10px' }}>
              <li>Geisibel</li>
              <li>Jesiane</li>
              <li>Lucas</li>
            </ul>
          </p>
        )}
      </Box>
    </Container>
  );
}
