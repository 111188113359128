import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchDriverRequest: ['data'],
  fetchDriverByClientRequest: ['data'],
  fetchDriverSuccess: ['data'],
  fetchDriverIdSuccess: ['data'],
  fetchDriverByClientSuccess: ['data'],
  fetchDriverFailure: [],
  fetchDriverClientClear: [],
  updateDriverRequest: ['data'],
  createDriverRequest: ['data'],
  fetchFilters: ['name'],
});

const INITIAL_STATE = {
  drivers: [],
  driversImutable: [],
  driverClients: [],
  driver: {},
  loading: false,
};

const fetchDriverRequest = (state = INITIAL_STATE) => ({
  ...state,
  drivers: [],
  driver: {},
  loading: true,
});

const fetchDriverByClientRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchDriverSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  drivers: data,
  driversImutable: data,
  loading: false,
});

const fetchDriverIdSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  driver: actions.data,
  loading: false,
});
const fetchDriverByClientSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  driverClients: actions.data,
  loading: false,
});

const fetchDriverFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    drivers: [],
  };
};

const fetchDriverClientClear = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  driverClients: [],
});
const updateDriverRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const createDriverRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchFilters = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    drivers: state.driversImutable.filter(driver => {
      const { name } = driver;
      if (name.toUpperCase().indexOf(actions.name.toUpperCase()) !== -1) {
        return driver;
      }
    }),
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_DRIVER_REQUEST]: fetchDriverRequest,
  [Types.FETCH_DRIVER_BY_CLIENT_REQUEST]: fetchDriverByClientRequest,
  [Types.FETCH_DRIVER_SUCCESS]: fetchDriverSuccess,
  [Types.FETCH_DRIVER_ID_SUCCESS]: fetchDriverIdSuccess,
  [Types.FETCH_DRIVER_BY_CLIENT_SUCCESS]: fetchDriverByClientSuccess,
  [Types.FETCH_DRIVER_FAILURE]: fetchDriverFailure,
  [Types.FETCH_DRIVER_CLIENT_CLEAR]: fetchDriverClientClear,
  [Types.UPDATE_DRIVER_REQUEST]: updateDriverRequest,
  [Types.CREATE_DRIVER_REQUEST]: createDriverRequest,
  [Types.FETCH_FILTERS]: fetchFilters,
});
