import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchDriverPermissionRequest: ['data'],
  fetchDriverPermissionSuccess: ['data'],
  fetchDriverPermissionFailure: [],
  updateDriverPermissionRequest: ['data'],
  updateDriverPermissionSuccess: [],
});

const INITIAL_STATE = {
  driverPermissions: [],
  loading: false,
};

const fetchDriverPermissionRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchDriverPermissionSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  driverPermissions: data,
  loading: false,
});

const fetchDriverPermissionFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    driverPermissions: [],
  };
};

const updateDriverPermissionRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
const updateDriverPermissionSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_DRIVER_PERMISSION_REQUEST]: fetchDriverPermissionRequest,
  [Types.FETCH_DRIVER_PERMISSION_SUCCESS]: fetchDriverPermissionSuccess,
  [Types.FETCH_DRIVER_PERMISSION_FAILURE]: fetchDriverPermissionFailure,
  [Types.UPDATE_DRIVER_PERMISSION_REQUEST]: updateDriverPermissionRequest,
  [Types.UPDATE_DRIVER_PERMISSION_SUCCESS]: updateDriverPermissionSuccess,
});
