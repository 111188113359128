import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '../../services/api';
import { Types } from '../ducks/authSauce';

export function* loginSaga(payload) {
  try {
    const response = yield call(Api.login, payload.data);
    yield put({ type: Types.LOGIN_SUCCESS, payload: response.data });
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
  } catch (error) {
    toast.error(error.response.data.error);
    yield put({ type: Types.LOGIN_FAILURE });
    localStorage.removeItem('token');
  }
}

export default function* rootSaga() {
  yield takeLatest(Types.LOGIN_REQUEST, loginSaga);
}
