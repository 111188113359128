import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

import SimpleInput from './simpleInput';

function Mask({ mask, onChange, value, disabled, plate, ...props }) {
  return (
    <>
      <InputMask
        mask={mask}
        onChange={onChange}
        maskChar={null}
        value={value}
        {...props}
        disabled={disabled}
      >
        {inputProps => <SimpleInput plate={plate} {...inputProps} />}
      </InputMask>
    </>
  );
}

Mask.propTypes = {
  mask: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  plate: PropTypes.bool,
  disabled: PropTypes.bool,
};

Mask.defaultProps = {
  plate: false,
  disabled: false,
};

export default Mask;
