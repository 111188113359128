import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '../../services/api';
import { Types } from '../ducks/driverPermissionSauce';

export function* requestDriverPermissionSaga(payload) {
  try {
    const response = yield call(api.fetchDriverPermission, payload.data);
    yield put({
      type: Types.FETCH_DRIVER_PERMISSION_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_DRIVER_PERMISSION_FAILURE });
  }
}

export function* updateDriverPermissionSaga(payload) {
  try {
    yield call(api.updateDriverPermission, payload.data);
    yield put({
      type: Types.UPDATE_DRIVER_PERMISSION_SUCCESS,
    });
    toast.success('Atualizado com sucesso!');
  } catch (error) {
    yield put({ type: Types.FETCH_DRIVER_PERMISSION_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(
      Types.FETCH_DRIVER_PERMISSION_REQUEST,
      requestDriverPermissionSaga
    ),
  ]);
  yield all([
    takeEvery(
      Types.UPDATE_DRIVER_PERMISSION_REQUEST,
      updateDriverPermissionSaga
    ),
  ]);
}
