import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdFileUpload } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import LoadingDefault from '../../components/animations/loadingAnimationDefault';
import Box from '../../components/Box';
import ContainerForm from '../../components/containers/containerForm';
import SimpleField from '../../components/fields/simpleField';
import HeaderCardForm from '../../components/headerCardForm';
import SimpleInput from '../../components/inputs/simpleInput';
import { Creators as typeActions } from '../../store/ducks/typeSauce';
import { UploadContainer } from './styles';

function Type({ history, match }) {
  const type = useSelector(state => state.typeState.type);
  const loading = useSelector(state => state.typeState.loading);
  const [image, setImage] = useState('');

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validate: values => {
      const err = {};
      const message = 'Campo obrigatório!';
      if (!values.name) err.name = message;

      return err;
    },
    onSubmit: data => {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('image', image);
      if (data.id) {
        dispatch(typeActions.updateTypeRequest(formData, data.id));
      } else {
        dispatch(typeActions.createTypeRequest(formData));
      }
    },
  });
  useEffect(() => {
    if (match.params.id) {
      dispatch(typeActions.fetchTypeRequest(`/${match.params.id}`));
    }
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(type)) {
      setImage(`${process.env.REACT_APP_FILES_URL}/${type.image}`);
      formik.setValues({ ...type });
    }
  }, [type, formik]);

  function changeImage(e) {
    setImage(e.target.files[0]);
    // e => console.log(e.target.files[0])
  }
  useEffect(() => {
    formik.setValues(formik.initialValues);
  }, []);

  return (
    <ContainerForm onSubmit={formik.handleSubmit}>
      <HeaderCardForm
        title="Formulário de tipos"
        route="/type"
        history={history}
        loading={loading}
      />
      {loading ? (
        <LoadingDefault />
      ) : (
        <Box tracker>
          <SimpleField>
            <div>Nome</div>
            <SimpleInput
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
            />
          </SimpleField>
          <UploadContainer>
            <input
              id="upload"
              type="file"
              accept="image/*"
              name="upload"
              onChange={changeImage}
            />
            <label htmlFor="upload">
              <MdFileUpload color="#fff" size={20} />
              Importar ícone
            </label>
          </UploadContainer>
          {/* <ProfileContainer>
            <FileUpload>
              <input
                type="file"
                accept="image/*"
                name="upload"
                onChange={changeImage}
              />
              <MdCameraAlt size={25} color="#fff" />
            </FileUpload>
          </ProfileContainer> */}
        </Box>
      )}
    </ContainerForm>
  );
}

Type.propTypes = {
  history: PropTypes.objectOf.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Type;
