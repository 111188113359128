import _ from 'lodash';
import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import React, { useEffect, useState, useCallback } from 'react';
import { MdCheck } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import 'rc-collapse/assets/index.css';
import { Creators as DriverPermissionActions } from '../../store/ducks/driverPermissionSauce';
import { Creators as DriverActions } from '../../store/ducks/driverSauce';
import { ContainerUser } from './styles';

export default function Permission({ height }) {
  const dispatch = useDispatch();
  const users = useSelector(state => state.userState.userClients);
  const [usersGroup, setUsersGroup] = useState([]);
  const [activeKey, setActiveKey] = useState('');

  useEffect(() => {
    if (!_.isEmpty(users)) {
      const usersGrouped = _.chain(users)
        .filter(u => u.active)
        .groupBy('client.name')
        .map((item, key) => ({
          client: key,
          users: item,
          clientId: item[0].client.id,
        }))
        .value();
      setUsersGroup(usersGrouped);
    } else {
      setUsersGroup([]);
    }
  }, [users]);

  const setActive = useCallback(
    user => {
      setActiveKey(user.id);
      dispatch(DriverActions.fetchDriverByClientRequest([user.client_id]));
      dispatch(
        DriverPermissionActions.fetchDriverPermissionRequest(`/${user.id}`)
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (activeKey) {
      dispatch(
        DriverPermissionActions.fetchDriverPermissionRequest(`/${activeKey}`)
      );
    }
  }, [activeKey]);

  return (
    <div style={{ height, overflow: 'scroll' }}>
      <Collapse accordion>
        {!_.isEmpty(usersGroup) &&
          usersGroup.map(item => {
            return (
              <Panel header={item.client} key={item.clientId}>
                {_.isEmpty(item.users)
                  ? 'Não possui usário cadastrado'
                  : item.users.map(user => (
                      <ContainerUser key={user.id}>
                        <div className="pretty p-icon p-round p-smooth">
                          <input
                            type="radio"
                            id="check"
                            checked={!!(user.id === activeKey)}
                            onClick={() => setActive(user)}
                          />
                          <div className="state p-info">
                            <MdCheck className="icon" />
                            <label htmlFor="check">{user.name}</label>
                          </div>
                        </div>
                      </ContainerUser>
                    ))}
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
}

Permission.propTypes = {
  height: PropTypes.string,
};

Permission.defaultProps = {
  height: '200px',
};
