import PropTypes from 'prop-types';
import React from 'react';

import { Container, ImageCar, Description, Title } from './styles';

function CardList({ data, changeValue }) {
  return (
    <div>
      {data.map(item => (
        <Container key={item.id} onClick={() => changeValue(item)}>
          <ImageCar
            src={`${process.env.REACT_APP_FILES_URL}/${item.image}`}
            alt="foto do carro"
          />
          <Title>{item.name}</Title>
          <Description>
            <div>{item.email}</div>
            <div>
              {item.cpf} - {item.phone}
            </div>
          </Description>
        </Container>
      ))}
    </div>
  );
}
CardList.propTypes = {
  data: PropTypes.arrayOf.isRequired,
  changeValue: PropTypes.func.isRequired,
};
export default CardList;
