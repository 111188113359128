import React, { useState } from 'react';
import { MdUpdate } from 'react-icons/md';

import Car from '../../assets/home/car.svg';
import Client from '../../assets/home/client.svg';
import Device from '../../assets/home/device.svg';
import Message from '../../assets/home/message.svg';
import Permission from '../../assets/home/permission.svg';
import PermissionDrivers from '../../assets/home/permissionDrivers.svg';
import Secretary from '../../assets/home/secretary.svg';
import Report from '../../assets/home/report.svg';
import Type from '../../assets/home/type.svg';
import User from '../../assets/home/user.svg';
import history from '../../routes/history';
import { Container, CarItem } from './styles';

function SideBar() {
  const [pathname, setPathname] = useState(window.location.pathname);
  function redirectRoute(route) {
    history.push(route);
    setPathname(window.location.pathname);
  }
  const [widthPage, setWidth] = useState(window.innerWidth);

  window.onresize = () => {
    setWidth(window.innerWidth);
  };
  return (
    <>
      <Container>
        <CarItem
          selected={pathname.indexOf('tracker') > -1}
          onClick={() => redirectRoute('/tracker')}
        >
          <img src={Car} alt="Carro" />
        </CarItem>
        <CarItem
          selected={pathname.indexOf('device') > -1}
          onClick={() => redirectRoute('/device')}
        >
          <img src={Device} alt="Dispositivo" />
        </CarItem>
        <CarItem
          selected={pathname.indexOf('permission') > -1}
          onClick={() => redirectRoute('/permission')}
        >
          <img src={Permission} alt="Permissão" />
        </CarItem>
        <CarItem
          selected={pathname.indexOf('user') > -1}
          onClick={() => redirectRoute('/user')}
        >
          <img src={User} alt="Usuário" />
        </CarItem>
        <CarItem
          selected={pathname.indexOf('client') > -1}
          onClick={() => redirectRoute('/client')}
        >
          <img src={Client} alt="Cliente" />
        </CarItem>
        <CarItem
          selected={pathname.indexOf('type') > -1}
          onClick={() => redirectRoute('/type')}
        >
          <img src={Type} alt="Tipo" />
        </CarItem>
        {widthPage <= 700 && (
          <CarItem
            selected={pathname.indexOf('message') > -1}
            onClick={() => redirectRoute('/message')}
          >
            <img src={Message} alt="Mensagem" />
          </CarItem>
        )}
        <CarItem
          selected={pathname.indexOf('logbook') > -1}
          onClick={() => redirectRoute('/logbook')}
        >
          <img src={PermissionDrivers} alt="Permissões Diário de Bordo" />
        </CarItem>
        <CarItem
          selected={pathname.indexOf('secretary') > -1}
          onClick={() => redirectRoute('/secretary')}
        >
          <img src={Secretary} alt="Secretaria" />
        </CarItem>
        <CarItem
          selected={pathname.indexOf('report') > -1}
          onClick={() => redirectRoute('/report')}
        >
          <img src={Report} alt="Ficha" />
        </CarItem>
        <CarItem
          selected={pathname.indexOf('news') > -1}
          onClick={() => redirectRoute('/news')}
        >
          <MdUpdate size={40} />
        </CarItem>
      </Container>
    </>
  );
}

export default SideBar;
