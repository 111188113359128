import React from 'react';

import car from '../../assets/home/car.svg';
import history from '../../routes/history';
import { Container } from './styles';

function Report() {
  return (
    <>
      <Container>
        <button
          type="button"
          onClick={() => history.push('/report/trackerByClient')}
        >
          <img src={car} alt="item de rastreamento" />
          <strong>Item de rastreamento</strong>
        </button>
      </Container>
    </>
  );
}

export default Report;
