import styled, { css } from 'styled-components';

const Box = styled.div`
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  height: 100%;
  @media screen and (min-width: 700px) {
    ${props =>
      props.tracker &&
      css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 90px 90px 90px 90px;
        grid-gap: 10px;
      `}
  }
  ${props =>
    props.secretary &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 90px 90px 90px 90px;
      grid-gap: 10px;
    `}
}
  ${props =>
    props.permission &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;
      }
    `}
`;

export default Box;
