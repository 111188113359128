import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-gap: 10px;
  margin: 10px;
`;

export const ContainerTable = styled.div`
  display: grid;
  grid-template-rows: 65px 1fr;
  grid-gap: 10px;
`;

export const CheckBox = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  margin: 10px;
  > label {
    padding-left: 5px;
    font-size: 15px;
    font-weight: 600;
  }
`;
