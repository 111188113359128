import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '../../services/api';
import { Types } from '../ducks/driverSauce';

export function* requestDriverSaga(payload) {
  try {
    const response = yield call(api.fetchDrivers, payload.data);
    if (_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_DRIVER_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_DRIVER_ID_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Types.FETCH_DRIVER_FAILURE });
  }
}
export function* requestDriverByClientSaga(payload) {
  try {
    const response = yield call(
      api.fetchDrivers,
      `?client_id=${payload.data[0]}`
    );
    yield put({
      type: Types.FETCH_DRIVER_BY_CLIENT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_DRIVER_FAILURE });
  }
}

export function* updateDriverSaga(payload) {
  try {
    yield call(api.updateDriver, payload.data);
    yield put({
      type: Types.FETCH_DRIVER_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/user'));
  } catch (error) {
    yield put({ type: Types.FETCH_DRIVER_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export function* createDriverSaga(payload) {
  try {
    yield call(api.createDriver, payload.data);
    yield put({
      type: Types.FETCH_DRIVER_REQUEST,
    });
    toast.success('Usuário cadastrado com sucesso!');
    yield put(push('/user'));
  } catch (error) {
    yield put({ type: Types.FETCH_DRIVER_FAILURE });
    switch (error.response.data.errors[0].message) {
      case 'email must be unique':
        toast.warning('E-mail não pode ser duplicado');

        break;
      case 'cpf must be unique':
        toast.warning('CPF não pode ser duplicado');

        break;

      default:
        toast.warning('Erro ao cadastrar o usuário');

        break;
    }
    // console.log();
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_DRIVER_REQUEST, requestDriverSaga)]);
  yield all([
    takeEvery(Types.FETCH_DRIVER_BY_CLIENT_REQUEST, requestDriverByClientSaga),
  ]);
  yield all([takeEvery(Types.UPDATE_DRIVER_REQUEST, updateDriverSaga)]);
  yield all([takeEvery(Types.CREATE_DRIVER_REQUEST, createDriverSaga)]);
}
