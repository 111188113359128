import styled from 'styled-components';

export const ButtonList = styled.button`
  padding-left: 5px;
  line-height: 3em;
  font-size: 12px;
  background: ${({ selected }) =>
    selected ? 'rgba(36, 83, 120, 0.5) !important' : 'transparent'};
  border: none;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;

  :nth-child(even) {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  :disabled {
    cursor: not-allowed;
  }
`;
