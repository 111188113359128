import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useFormik } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import LoadingDefault from '../../components/animations/loadingAnimationDefault';
import Box from '../../components/Box';
import ContainerForm from '../../components/containers/containerForm';
import SimpleField from '../../components/fields/simpleField';
import HeaderCardForm from '../../components/headerCardForm';
import MaskedInput from '../../components/inputs/maskedInput';
import SimpleInput from '../../components/inputs/simpleInput';
import history from '../../routes/history';
import api from '../../services/api';
import { CheckBox } from './styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function Form({ match, history }) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: '',
      date: '',
      title: '',
      description: EditorState.createEmpty(),
      public: '',
    },
    validate: values => {
      const err = {};
      const message = 'Campo obrigatório!';
      if (!values.date) err.date = message;
      if (!values.title) err.title = message;
      // if (!values.description) err.description = message;

      // console.log(err);
      return err;
    },
    onSubmit: async data => {
      const dataModel = { ...data };
      dataModel.date = parse(dataModel.date, 'dd/MM/yyyy', new Date());
      dataModel.date = format(new Date(dataModel.date), 'yyyy-MM-dd');

      dataModel.description = draftToHtml(
        convertToRaw(data.description.getCurrentContent())
      );
      if (dataModel.id) {
        await api.updateNews(dataModel);
        history.push('/news');
      } else {
        await api.createNews(dataModel);
        history.push('/news');
      }
      history.push('/news');
    },
  });

  function editorChange(state) {
    formik.setFieldValue('description', state);
  }

  useEffect(() => {
    async function getMatch() {
      if (match.params.id) {
        const { data: news } = await api.fetchNewsById(match.params.id);
        news.date = parse(news.date, 'yyyy-MM-dd', new Date());
        news.date = format(new Date(news.date), 'dd/MM/yyyy');
        const contentBlock = htmlToDraft(news.description);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);

          formik.setValues({
            ...news,
            description: editorState,
          });
        } else {
          formik.setValues({
            ...news,
            description: EditorState.createEmpty(),
          });
        }
        setLoading(false);
      } else {
        //
        formik.resetForm();
        setLoading(false);
      }
    }
    getMatch();
  }, [match.params.id]);

  return (
    <ContainerForm onSubmit={formik.handleSubmit}>
      <HeaderCardForm
        title="Formulário de atualizações"
        route="/news"
        history={history}
        loading={false}
      />
      {loading ? (
        <LoadingDefault />
      ) : (
        <Box>
          <SimpleField>
            <div>Título</div>
            <SimpleInput
              name="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              error={formik.touched.title && formik.errors.title}
            />
          </SimpleField>
          <SimpleField>
            <div>Data</div>
            <MaskedInput
              mask="99/99/9999"
              name="date"
              onChange={formik.handleChange}
              value={formik.values.date}
              error={formik.touched.date && formik.errors.date}
            />
          </SimpleField>
          <Editor
            editorState={formik.values.description}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={editorChange}
          />
          {formik.values.active !== '' && (
            <CheckBox>
              <input
                id="public"
                type="checkbox"
                defaultChecked={formik.values.public}
                checked={formik.values.public}
                onChange={() =>
                  formik.setFieldValue('public', !formik.values.public)
                }
              />
              <label htmlFor="public">Publicado</label>
            </CheckBox>
          )}
        </Box>
      )}
    </ContainerForm>
  );
}

Form.propTypes = {
  history: PropTypes.objectOf.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Form;
