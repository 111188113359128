import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import authReducer from './authSauce';
import clientReducer from './clientSauce';
import deviceReducer from './deviceSauce';
import driverPermissionReducer from './driverPermissionSauce';
import driverReducer from './driverSauce';
import messageReducer from './messageSauce';
import permissionReducer from './permissionSauce';
import trackerReducer from './trackerSauce';
import secretaryReducer from './secretarySauce'
import typeReducer from './typeSauce';
import userReducer from './userSauce';

const rootReducer = history =>
  combineReducers({
    authState: authReducer,
    clientState: clientReducer,
    trackerState: trackerReducer,
    secretaryState: secretaryReducer,
    typeState: typeReducer,
    userState: userReducer,
    driverState: driverReducer,
    permissionState: permissionReducer,
    driverPermissionState: driverPermissionReducer,
    deviceState: deviceReducer,
    messageState: messageReducer,
    router: connectRouter(history),
  });

export default rootReducer;
