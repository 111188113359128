import _ from 'lodash';
import Collapse, { Panel } from 'rc-collapse';
import React, { useEffect, useState, useCallback } from 'react';
import { MdCheck } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as MessageActions } from '../../store/ducks/messageSauce';
import { Creators as TrackerActions } from '../../store/ducks/trackerSauce';

import 'rc-collapse/assets/index.css';
import { ContainerUser } from './styles';

export default function Permission({ setHeight }) {
  const dispatch = useDispatch();
  const trackers = useSelector(state => state.trackerState.trackers);
  const [trackersGrouped, setTrackerGrouped] = useState([]);
  const loading = useSelector(state => state.trackerState.loading);
  const [activeKey, setActiveKey] = useState('');
  useEffect(() => {
    dispatch(TrackerActions.fetchTrackerRequest());
    dispatch(MessageActions.setTrackerMessage({}));
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEmpty(trackers)) {
      const groupBy = _.chain(trackers)
        .groupBy('client.name')
        .map((item, key) => ({
          client: key,
          tracker: item.map(tracker => ({
            ...tracker,
            checked: false,
          })),
          clientId: item[0].client.id,
        }))
        .sortBy('client')
        .value();
      // const usersGrouped = _.groupBy(users, 'client.name');
      setTrackerGrouped(groupBy);
    } else {
      setTrackerGrouped([]);
    }
  }, [trackers]);

  const setActive = useCallback(
    car => {
      setActiveKey(car.id);
      dispatch(MessageActions.setTrackerMessage(car));
    },
    [dispatch]
  );

  return (
    <div style={{ height: setHeight, overflow: 'scroll' }}>
      {loading ? (
        'loading'
      ) : (
        <Collapse accordion style={{ height: '100%' }}>
          {!_.isEmpty(trackersGrouped) &&
            trackersGrouped.map(item => {
              return (
                <Panel header={item.client} key={item.clientId}>
                  {_.isEmpty(item.tracker)
                    ? 'Não possui usário cadastrado'
                    : item.tracker.map(car => (
                        <ContainerUser key={car.id}>
                          <div className="pretty p-icon p-round p-smooth">
                            <input
                              type="radio"
                              id="check"
                              checked={!!(car.id === activeKey)}
                              onClick={() => setActive(car)}
                            />
                            <div className="state p-info">
                              <MdCheck className="icon" />
                              <label htmlFor="check">{car.plate}</label>
                            </div>
                          </div>
                        </ContainerUser>
                      ))}
                </Panel>
              );
            })}
        </Collapse>
      )}
    </div>
  );
}
