import PropTypes from 'prop-types';
import React from 'react';

import { Container, ImageCar, Description, Title, Scroll } from './styles';

function CardList({ data, changeValue }) {
  return (
    <Scroll>
      {data.map(item => (
        <Container key={item.id} onClick={() => changeValue(item)}>
          <ImageCar
            src={`${process.env.REACT_APP_FILES_URL}/${item.type.image}`}
            alt="foto do carro"
          />
          <Title>{item.plate}</Title>
          <Description>
            <div>{item.client.name}</div>
            <div>{`${item.model} ( ${item.year} )`}</div>
          </Description>
        </Container>
      ))}
    </Scroll>
  );
}
CardList.propTypes = {
  data: PropTypes.arrayOf.isRequired,
  changeValue: PropTypes.func.isRequired,
};
export default CardList;
