import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { MdExitToApp } from 'react-icons/md';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import LogoIcon from '../../assets/logo.svg';
import SimpleButton from '../../components/buttons/simpleButton';
import ListClient from '../../components/list/ListClient';
import history from '../../routes/history';
import { Creators as AuthActions } from '../../store/ducks/authSauce';
import { Creators as ClientActions } from '../../store/ducks/clientSauce';
import {
  Container,
  ButtonsContainer,
  ButtonFilter,
  ButtonLogo,
  TitleContainer,
  Logo,
  ClientIcon,
  ClientButton,
  Title,
  ModalContainer,
  Descripition,
  SimpleInput,
} from './styles';

function Navbar({ token, loginFailure }) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientInput, setClientInput] = useState('');
  useEffect(() => {
    setClientName(localStorage.getItem('clientName'));
  }, []);

  useEffect(() => {
    setClientInput('');
    dispatch(ClientActions.fetchFilters(''));
  }, [modal]);

  function handleLogout() {
    /* width: 30px; */
    localStorage.removeItem('token');
    loginFailure();
    history.push('/auth/login');
  }

  function selectClient({ id, name }) {
    localStorage.setItem('clientId', id);
    localStorage.setItem('clientName', name);
    setClientName(name);
    setModal(false);
  }

  return (
    <Container>
      {token && (
        <>
          <header>
            <ButtonLogo onClick={() => history.push('/home')} type="button">
              <Logo src={LogoIcon} alt="logo" />
              <TitleContainer>
                {/* <Title>GEORASTREAMENTO</Title> */}
                {/* <Description>Ferramenta Administrativa</Description> */}
              </TitleContainer>
            </ButtonLogo>
            <ClientButton onClick={() => setModal(true)}>
              <ClientIcon src="/static/media/client.fbd12510.svg" />
              <ButtonFilter>{clientName || 'Selecionar Cliente'}</ButtonFilter>
            </ClientButton>
            <ButtonsContainer>
              <MdExitToApp size={30} color="#0991d1" onClick={handleLogout} />
            </ButtonsContainer>
          </header>
          <Modal
            visible={modal}
            height="320"
            width="300"
            effect="fadeInUp"
            onClickAway={() => setModal(false)}
          >
            <ModalContainer>
              <Title>Clientes</Title>
              <Descripition>
                <SimpleInput
                  placeholder="Filtrar Cliente"
                  onChange={({ target: { value } }) => {
                    setClientInput(value);
                    dispatch(ClientActions.fetchFilters(value));
                  }}
                  value={clientInput}
                />
                <ListClient selectClient={selectClient} />
              </Descripition>
              <ButtonsContainer>
                <SimpleButton
                  text="Cancelar"
                  bg="#ff4e4e"
                  color="#eee"
                  onClick={() => setModal(false)}
                />
              </ButtonsContainer>
            </ModalContainer>
          </Modal>
        </>
      )}
    </Container>
  );
}

Navbar.propTypes = {
  token: PropTypes.string.isRequired,
  loginFailure: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  token: state.authState.token,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
