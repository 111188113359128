import React from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';
import SimpleButton from '../../components/buttons/simpleButton';
import { Creators as messageActions } from '../../store/ducks/messageSauce';
import {
  ModalContainer,
  Title,
} from './styles';

export default function SleepModal() {
  const dispatch = useDispatch();
  const visible = useSelector(state => state.messageState.modal === 'sleep');
  const device = useSelector(state => state.deviceState.device);

  function sendMessage(type) {
    if (device.model === 'SUNTECH' && device.type === 'ST4315U') {
      if (type === 'Enable') {
        window.location.href = `sms://${device.phone}?body=PRG;${device.imei};19;01`;
      } else {
        window.location.href = `sms://${device.phone}?body==PRG;${device.imei};19;00`;
      }
    } else if (device.model === 'SUNTECH') {
      window.location.href = `sms://${device.phone}?body=ST300SVC;${device.imei};02;0;300;1;0;0;0;1;1;1;0;0;0;0`;
    } else if (device.model === 'QUECLINK') {
      window.location.href = `sms://${device.phone}?body=`;
    } else if (device.model === 'VITANA') { 
      if (type === 'Enable') {
        window.location.href = `sms://${device.phone}?body=0000,124,1`;
      } else {
        window.location.href = `sms://${device.phone}?body=0000,124,0`;
      }
    }
    return '';
  }
  return (
    <Modal
      visible={visible}
      onClickAway={() => dispatch(messageActions.setEnableModal(false))}
      height="240"
      width="300"
      effect="fadeInUp"
    >
      <ModalContainer>
        <Title>Modo sleep</Title>
          <SimpleButton
            text="Habilitar"
            bg="#228C22"
            color="#eee"
            onClick={() => sendMessage('Enable')}
          />
          <SimpleButton
            text="Desabilitar"
            bg="#01659e"
            color="#eee"
            onClick={() => sendMessage('Disable')}
          />
          <SimpleButton
            text="Cancelar"
            bg="#ff4e4e"
            color="#eee"
            onClick={() => dispatch(messageActions.setEnableModal(false))}
          />
      </ModalContainer>
    </Modal>
  );
}
