import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as clientActions } from '../../store/ducks/clientSauce';
import { Creators as trackerActions } from '../../store/ducks/trackerSauce';
import { Creators as secretaryActions } from '../../store/ducks/secretarySauce';
import { ButtonList } from './style';

function ListClient({ selectClient }) {
  const dispatch = useDispatch();
  const clients = useSelector(state => state.clientState.clients);

  useEffect(() => {
    dispatch(clientActions.fetchClientRequest());
    // api.fetchClient().then(({data}) => setClients(data))
  }, []);

  return (
    <>
      <div>
        {clients &&
          clients.map(client => (
            <ButtonList
              type="button"
              onClick={() => {
                dispatch(
                  trackerActions.fetchTrackerRequest(
                    `?id_clients=[${client.id}]`
                  )
                );
                dispatch(
                  secretaryActions.fetchSecretaryRequest(
                    `?id_clients=[${client.id}]`
                  )
                );
                selectClient({ id: client.id, name: client.name });
              }}
              selected={
                Number(localStorage.getItem('clientId')) === Number(client.id)
              }
              disabled={window.location.href.includes('form')}
            >
              {client.name}
            </ButtonList>
          ))}
      </div>
    </>
  );
}

ListClient.propTypes = {
  selectClient: PropTypes.func.isRequired,
};

export default ListClient;
