import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  > div {
    font-size: 20px;
    font-weight: 600;
  }
`;
