import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingDefault from '../../components/animations/loadingAnimationDefault';
import Box from '../../components/Box';
import ContainerForm from '../../components/containers/containerForm';
import SelectBrandField from '../../components/fields/selectBrandField';
import SelectClientField from '../../components/fields/selectClientField';
import SelectSecretaryField from '../../components/fields/selectSecretaryField';
import SelectColorField from '../../components/fields/selectColorField';
import SelectField from '../../components/fields/selectField';
import SelectImeiField from '../../components/fields/selectImeiField';
import SelectTypeField from '../../components/fields/selectTypeField';
import SimpleField from '../../components/fields/simpleField';
import HeaderCardForm from '../../components/headerCardForm';
import MaskedInput from '../../components/inputs/maskedInput';
import SimpleInput from '../../components/inputs/simpleInput';
import { Creators as TrackerActions } from '../../store/ducks/trackerSauce';
import { CheckBox } from './styles';

function TrackerForm({ history, match }) {
  const tracker = useSelector(state => state.trackerState.tracker);
  const loading = useSelector(state => state.trackerState.loading);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      client_id: Number(localStorage.getItem('clientId')),
      label: '',
      plate: '',
      chassis: '',
      type_id: '',
      secretary_id: undefined,
      brand: '',
      model: '',
      color: '',
      year: '',
      school_bus: false,
      average: null,
      hour_meter: '',
      speed_max: 110,
      idle_time: 30,
      device_id: '',
      driving_time_start: '00:00:00',
      driving_time_end: '23:59:59',
      alert_speed: true,
      active: true,
      client: { name: '' },
      secretary: { name: '' },
      type: { name: '' },
      wereLabelAndPlateSame: false,
    },
    validate: values => {
      const err = {};
      const message = 'Campo obrigatório!';
      if (!values.client_id) err.client_id = message;
      if (!values.type_id) err.type_id = message;
      if (!values.brand) err.brand = message;
      if (!values.model) err.model = message;
      if (!values.color) err.color = message;
      if (!values.year) err.year = message;
      if (!values.plate) err.plate = message;
      if (!values.chassis) err.chassis = message;
      // if (!values.device_id) err.device_id = message;
      return err;
    },
    onSubmit: data => {
      console.log(data);
      if (data.hour_meter === '') data.hour_meter = false;
      data.plate = data.plate.toUpperCase();

      console.log('onSubmit', {
        label: data.label,
        plate: data.plate,
        wereLabelAndPlateSame: data.wereLabelAndPlateSame,
      });

      // NAO TEM CAMPO PARA PREENCHER O LABEL NESSA TELA
      // AO CRIAR UM NOVO REGISTRO LABEL ESTARA VAZIO E SERA REPLICADO O VALOR DA PLACA
      // AO ALTERAR UM REGISTRO NAO DEVE ALTERAR O VALOR DE LABEL QUE É DE DOMINIO DO CLIENTE
      // MAS AO ALTERAR O REGISTRO, AO CARREGAR OS DADOS LABEL E PLATE ERAM IGUAIS, MANTER OS DOIS VALORES IGUAIS
      if (!data.label || data.wereLabelAndPlateSame) {
        data.label = data.plate.toUpperCase();
      }

      data.average = Number(data.average);
      if (isNaN(data.average) || data.average < 0) {
        data.average = null;
      }

      if (data.id) {
        dispatch(TrackerActions.updateTrackerRequest(data));
      } else {
        dispatch(TrackerActions.createTrackerRequest(data));
      }
    },
  });

  useEffect(() => {
    if (match.params.id) {
      dispatch(TrackerActions.fetchTrackerRequest(`/${match.params.id}`));
    }
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (!_.isEmpty(tracker)) {
      console.log({
        label: tracker.label,
        plate: tracker.plate,
        wereLabelAndPlateSame: tracker.plate === tracker.label,
      });
      formik.setValues({
        ...tracker,
        wereLabelAndPlateSame: tracker.plate === tracker.label,
        secretary: tracker.secretary ? tracker.secretary : { name: '' },
      });
    }
    // console.log(secretaries);
  }, [tracker]);

  useEffect(() => {
    // formik.setFieldValue('hour_meter', true);
  }, [formik.values.client_id]);

  useEffect(() => {
    formik.resetForm();
    // formik.setFieldValue('hour_meter', true);
  }, []);
  return (
    <ContainerForm onSubmit={formik.handleSubmit} enableReinitialize>
      <HeaderCardForm
        title="Formulário de item de rastreamento"
        route="/tracker"
        history={history}
        loading={loading}
      />
      {loading ? (
        <LoadingDefault />
      ) : (
        <Box tracker>
          <SimpleField>
            <div>Placa</div>
            <MaskedInput
              mask="aaa-9*99"
              plate
              name="plate"
              onChange={formik.handleChange}
              value={formik.values.plate}
              error={formik.touched.plate && formik.errors.plate}
            />
          </SimpleField>
          <SelectClientField
            changeClientValue={option =>
              formik.setFieldValue('client_id', option.value)
            }
            defaultValue={{
              label: formik.values.client.name,
              value: formik.values.cliet_id,
            }}
            error={!!(formik.touched.client_id && formik.errors.client_id)}
          />
          <SelectSecretaryField
            clientId={formik.values.client_id}
            changeSecretaryValue={option =>
              formik.setFieldValue(
                'secretary_id',
                option ? option.value : option
              )
            }
            defaultValue={{
              label: formik.values.secretary.name,
              value: formik.values.secretary_id,
            }}
            error={
              !!(formik.touched.secretary_id && formik.errors.secretary_id)
            }
          />
          <SelectBrandField
            changeBrandValue={option =>
              formik.setFieldValue('brand', option.value || '')
            }
            defaultValue={{
              label: formik.values.brand,
              value: formik.values.brand,
            }}
            error={!!(formik.touched.brand && formik.errors.brand)}
          />
          <SimpleField>
            <div>Modelo</div>
            <SimpleInput
              name="model"
              onChange={formik.handleChange}
              value={formik.values.model}
              error={!!(formik.touched.model && formik.errors.model)}
            />
          </SimpleField>
          <SimpleField>
            <div>Ano</div>
            <MaskedInput
              mask="9999/9999"
              name="year"
              onChange={formik.handleChange}
              value={formik.values.year}
              error={formik.touched.year && formik.errors.year}
            />
          </SimpleField>

          <SelectColorField
            changeColorValue={option =>
              formik.setFieldValue('color', option.value)
            }
            defaultValue={{
              label: formik.values.color,
              value: formik.values.color,
            }}
            error={!!(formik.touched.color && formik.errors.color)}
          />
          <SimpleField>
            <div>Chassis</div>
            <SimpleInput
              name="chassis"
              onChange={formik.handleChange}
              value={formik.values.chassis}
              error={!!(formik.touched.chassis && formik.errors.chassis)}
            />
          </SimpleField>
          <SelectTypeField
            changeTypeValue={option =>
              formik.setFieldValue('type_id', option.value)
            }
            defaultValue={{
              label: formik.values.type.name,
              value: formik.values.type_id,
            }}
            error={!!(formik.touched.type_id && formik.errors.type_id)}
          />
          {/* {formik.values.hour_meter ? 'Horimetro' : 'Hodometro'} */}
          {/* <SelectField
            title="Medidor"
            placeholder="Selecione um medidor"
            options={[
              { label: 'Horimetro', value: true },

              { label: 'Hodometro', value: false },
            ]}
            defaultValue={{
              label: formik.values.hour_meter ? 'Horimetro' : 'Hodometro',
              value: formik.values.hour_meter,
            }}
            changeValueSelect={option =>
              formik.setFieldValue('hour_meter', option.value)
            }
          /> */}
          {/* {formik.values.hour_meter !== '' && ( */}
          <CheckBox>
            <input
              id="hourMeter"
              type="checkbox"
              defaultChecked={formik.values.hour_meter}
              checked={formik.values.hour_meter}
              onChange={() =>
                formik.setFieldValue('hour_meter', !formik.values.hour_meter)
              }
            />
            <label htmlFor="hourMeter">Horimetro</label>
          </CheckBox>
          <CheckBox>
            <input
              id="schoolBus"
              type="checkbox"
              defaultChecked={formik.values.school_bus}
              checked={formik.values.school_bus}
              onChange={() =>
                formik.setFieldValue('school_bus', !formik.values.school_bus)
              }
            />
            <label htmlFor="schoolBus">Transporte Escolar</label>
          </CheckBox>
          {/* )} */}

          <SelectField
            title="Status"
            placeholder="Selecione um status"
            options={[
              { label: 'Ativo', value: true },
              { label: 'Inativo', value: false },
            ]}
            defaultValue={{
              label: formik.values.active ? 'Ativo' : 'Inativo',
              value: formik.values.active,
            }}
            changeValueSelect={option =>
              formik.setFieldValue('active', option.value)
            }
          />
          <SelectImeiField
            link={`/device/form/${formik.values.device_id}`}
            changeImeiValue={option =>
              option === null
                ? formik.setFieldValue('device_id', '')
                : formik.setFieldValue('device_id', option.value)
            }
            defaultValue={{
              label: formik.values.device_id,
              value: formik.values.device_id,
            }}
            error={!!(formik.touched.device_id && formik.errors.device_id)}
          />
          <SimpleField>
            <div title="consumo médio de combustivel">Consumo</div>
            <SimpleInput
              name="average"
              onChange={formik.handleChange}
              value={formik.values.average}
              error={!!(formik.touched.model && formik.errors.model)}
            />
          </SimpleField>
        </Box>
      )}
    </ContainerForm>
  );
}

TrackerForm.propTypes = {
  history: PropTypes.objectOf.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TrackerForm;
