import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as TypeActions } from '../../store/ducks/typeSauce';
import SelectField from './selectField';

function SelectTypeField({ changeTypeValue, error, defaultValue }) {
  const loading = useSelector(state => state.clientState.loading);
  const types = useSelector(state =>
    state.typeState.types
      .map(type => ({ label: type.name, value: type.id }))
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      })
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(TypeActions.fetchTypeRequest());
  }, [dispatch]);

  return (
    <SelectField
      options={types || []}
      loading={loading}
      title="Tipo"
      changeValueSelect={option => changeTypeValue(option)}
      placeholder="Selecione o tipo"
      defaultValue={defaultValue}
      error={error}
    />
  );
}

SelectTypeField.propTypes = {
  changeTypeValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.bool,
};

SelectTypeField.defaultProps = {
  defaultValue: {},
  error: false,
};
export default SelectTypeField;
