import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchClientRequest: ['data'],
  fetchClientSuccess: ['data'],
  fetchClientIdSuccess: ['data'],
  fetchClientFailure: [],
  updateClientRequest: ['data'],
  createClientRequest: ['data'],
  fetchFilters: ['name'],
});

const INITIAL_STATE = {
  clients: [],
  clientsImutable: [],
  client: {},
  loading: false,
};

const fetchClientRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchClientSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  clients: data,
  clientsImutable: data,
  loading: false,
});

const fetchClientIdSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  client: actions.data,
  loading: false,
});

const fetchClientFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    Clients: [],
  };
};

const updateClientRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const createClientRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchFilters = (state = INITIAL_STATE, { name = '' }) => {
  return {
    ...state,
    clients: state.clientsImutable.filter(
      client => client.name.toUpperCase().includes(name.toUpperCase() || '')
    ),
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_CLIENT_REQUEST]: fetchClientRequest,
  [Types.FETCH_CLIENT_SUCCESS]: fetchClientSuccess,
  [Types.FETCH_CLIENT_ID_SUCCESS]: fetchClientIdSuccess,
  [Types.FETCH_CLIENT_FAILURE]: fetchClientFailure,
  [Types.UPDATE_CLIENT_REQUEST]: updateClientRequest,
  [Types.CREATE_CLIENT_REQUEST]: createClientRequest,
  [Types.FETCH_FILTERS]: fetchFilters,
});
