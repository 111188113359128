import { useFormik } from 'formik';
import React from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';

import SimpleButton from '../../components/buttons/simpleButton';
import MaskedInput from '../../components/inputs/maskedInput';
import { Creators as messageActions } from '../../store/ducks/messageSauce';
import {
  ModalContainer,
  Title,
  Descripition,
  ButtonsContainer,
} from './styles';

export default function TimerModalOdometerModal() {
  const dispatch = useDispatch();
  const visible = useSelector(state => state.messageState.modal === 'odometer');
  const device = useSelector(state => state.deviceState.device);
  const formik = useFormik({
    initialValues: {
      odometer: '',
    },
    validate: value => {
      const err = {};
      if (!value.odometer) err.odometer = 'Campo obrigatório!';
      return err;
    },
    onSubmit: data => {
      if (device.model === 'SUNTECH' && device.type === 'ST4315U') {
        window.location.href = `sms://${device.phone}?body=CMD;${device.imei};05;03;${data.odometer}000`;
      } else if (device.model === 'SUNTECH') {
        window.location.href = `sms://${device.phone}?body=ST300CMD;${device.imei};02;SetOdometer=${data.odometer}000`;
      } else if (device.model === 'QUECLINK') {
        window.location.href = `sms://${device.phone}?body=AT+GTCFG=gv75,gv75,gv75,1,${data.odometer},,,3F,0,,7DFF,,1,0,300,0,1,0,0,1F,0,FFFF$`;
      } else if (device.model === 'VITANA') {
        window.location.href = `sms://${device.phone}?body=0000,610,${data.odometer}000`;
      }
    },
  });
  return (
    <Modal
      visible={visible}
      onClickAway={() => dispatch(messageActions.setEnableModal(false))}
      height="180"
      width="300"
      effect="fadeInUp"
    >
      <ModalContainer onSubmit={formik.handleSubmit}>
        <Title>Odômetro</Title>
        <Descripition>
          <MaskedInput
            placeholder="Digite o odômetro"
            mask="999999999999999"
            name="odometer"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.odometer}
            error={!!(formik.touched.odometer && formik.errors.odometer)}
          />
        </Descripition>
        <ButtonsContainer>
          <SimpleButton
            text="Cancelar"
            bg="#ff4e4e"
            color="#eee"
            onClick={() => dispatch(messageActions.setEnableModal(false))}
          />
          <SimpleButton
            type="subbmit"
            text="Enviar"
            bg="#01659e"
            color="#eee"
          />
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  );
}
