import React, { useRef, useState, useLayoutEffect } from 'react';

import Container from '../../components/containers/containerSimple';
import history from '../../routes/history';
import { Header } from '../Message/styles';
import Table from './table';

function Device() {
  const targetRef = useRef();
  const [height, setHeight] = useState('0px');
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight - 75}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight('0px');
    dimension();
  }, []);
  return (
    <Container>
      <Header>
        <div>Dispositivos</div>
      </Header>
      <div style={{ height: '100%' }} ref={targetRef}>
        <Table history={history} height={height} />
      </div>
    </Container>
  );
}

export default Device;
