import styled, { css } from 'styled-components';

const Box = styled.div`
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  justify-content: center;
  height: 100%;
  > form {
    display: grid;
    grid-template-columns: ${props => {
      if (props.columns === 1) {
        return '1fr 30px';
      }
      if (props.columns === 2) {
        return '1fr 1fr 30px';
      }
    }};

    grid-gap: 5px;
    > input {
      height: 35px;
    }
    > button {
      border: none;
      background: transparent;
      width: 100%;
      border-radius: 50%;
    }
  }
  @media screen and (min-width: 700px) {
    ${props =>
      props.tracker &&
      css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 90px 90px 90px 90px;
        grid-gap: 10px;
      `}
  }
  ${props =>
    props.permission &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;
      }
    `}
`;

export default Box;
