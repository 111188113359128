import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import horimeter from '../../assets/messages/horimeter.svg';
import odometer from '../../assets/messages/odometer.svg';
import output from '../../assets/messages/output.svg';
import server from '../../assets/messages/server.svg';
import sleep from '../../assets/messages/sleep.svg';
import timer from '../../assets/messages/timer.svg';
import { Creators as deviceActions } from '../../store/ducks/deviceSauce';
import { Creators as messageActions } from '../../store/ducks/messageSauce';
import { ContainerMessage, CardMessage } from './styles';

export default function Message() {
  const dispatch = useDispatch();
  const tracker = useSelector(state => state.messageState.tracker);
  const device = useSelector(state => state.deviceState.device);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isHourMeter, setIsHourMeter] = useState(true);
  useEffect(() => {
    if (!_.isEmpty(tracker)) {
      dispatch(deviceActions.fetchDeviceRequest(`/${tracker.device_id}`));
      if (tracker.hour_meter) setIsHourMeter(true);
      else setIsHourMeter(false);
      setIsDisabled(false);
    } else {
      setIsHourMeter(true);
      setIsDisabled(true);
    }
  }, [tracker, dispatch]);
  useEffect(() => {
    if (!_.isEmpty(device)) {
      if (device.model === 'XEXUN') {
        setIsDisabled(true);
        setIsHourMeter(true);
      }
    }
  }, [device]);
  function setModalActive(modal) {
    if (!isDisabled) {
      dispatch(messageActions.setEnableModal(modal));
    }
  }
  return (
    <ContainerMessage>
      <CardMessage
        disabled={isHourMeter}
        onClick={() => setModalActive('odometer')}
      >
        <img src={odometer} alt="Odômetro" />
      </CardMessage>
      <CardMessage
        disabled={isDisabled}
        onClick={() => setModalActive('sleep')}
      >
        <img src={sleep} alt="Modo sleep" />
      </CardMessage>
      <CardMessage
        disabled={isDisabled}
        onClick={() => setModalActive('timer')}
      >
        <img src={timer} alt="Tempo de transmissão" />
      </CardMessage>
      <CardMessage
        disabled={isDisabled || !isHourMeter}
        onClick={() => setModalActive('orimeter')}
      >
        <img src={horimeter} alt="Horimetro" />
      </CardMessage>
      <CardMessage
        disabled={isDisabled}
        onClick={() => setModalActive('server')}
      >
        <img src={server} alt="Servidor" />
      </CardMessage>
      <CardMessage
        disabled={isDisabled}
        onClick={() => setModalActive('output')}
      >
        <img src={output} alt="Saida" />
      </CardMessage>
    </ContainerMessage>
  );
}
