import moment from 'moment';

function numberSort({ arr, number, type, header }) {
  if (type === 1) {
    return arr.filter(value => value[header] <= number);
  } else if (type === 2) {
    return arr.filter(value => value[header] === number);
  } else if (type === 3) {
    return arr.filter(value => value[header] >= number);
  }
}

function dateSort({ arr, date, header }) {
  return arr.filter(
    value =>
      moment(value[header], 'DD/MM/YYYY HH:SS').format('YYYY/MM/DD') ==
      moment(date).format('YYYY/MM/DD')
  );
}

export { numberSort, dateSort };
