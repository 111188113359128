import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchDeviceRequest: ['data'],
  fetchDeviceSuccess: ['data'],
  fetchDeviceIdSuccess: ['data'],
  fetchDeviceFailure: [],
  updateDeviceRequest: ['data'],
  createDeviceRequest: ['data'],
  fetchFilters: ['imei', 'phone'],
});

const INITIAL_STATE = {
  devices: [],
  devicesImutable: [],
  device: {},
  loading: false,
};

const fetchDeviceRequest = (state = INITIAL_STATE) => ({
  ...state,
  devices: [],
  device: {},
  loading: true,
});

const fetchDeviceSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  devices: data,
  devicesImutable: data,
  loading: false,
});

const fetchDeviceIdSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  device: actions.data,
  loading: false,
});

const fetchDeviceFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    devices: [],
  };
};

const updateDeviceRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const createDeviceRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchFilters = (state = INITIAL_STATE, { imei, phone }) => {
  phone = phone && phone.replace(/[^\d]/g, '');
  return {
    ...state,
    devices: state.devicesImutable
      .filter(device => device.imei && device.imei.includes(imei || ''))
      .filter(
        device =>
          device.phone &&
          device.phone.replace(/[^\d]/g, '').includes(phone || '')
      ),
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_DEVICE_REQUEST]: fetchDeviceRequest,
  [Types.FETCH_DEVICE_SUCCESS]: fetchDeviceSuccess,
  [Types.FETCH_DEVICE_ID_SUCCESS]: fetchDeviceIdSuccess,
  [Types.FETCH_DEVICE_FAILURE]: fetchDeviceFailure,
  [Types.UPDATE_DEVICE_REQUEST]: updateDeviceRequest,
  [Types.CREATE_DEVICE_REQUEST]: createDeviceRequest,
  [Types.FETCH_FILTERS]: fetchFilters,
});
