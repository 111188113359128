import styled from 'styled-components';

const simpleInput = styled.input`
  width: 100%;
  height: 40px;
  margin: 5px auto;
  padding: 0 10px;
  background: ${props =>
    props.background ? props.background : 'rgba(0, 0, 0, 0.01)'};
  color: ${props => (props.color ? props.color : '#333')};
  border-radius: 500px;
  border: 1px solid #333;
  font-size: 16px;
  border: 1px solid ${props => (props.error ? 'rgba(257,0,0, 1)' : '#ddd')};
  text-transform: ${props => props.plate && 'uppercase'};
`;

export default simpleInput;
