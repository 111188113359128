import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  loginRequest: ['data'],
  loginSuccess: [],
  loginFailure: [],
});

const INITIAL_STATE = {
  loading: false,
  token: localStorage.getItem('token'),
  user: JSON.parse(localStorage.getItem('user')) || { id: null },
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const success = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  token: action.payload.token,
  user: { ...action.payload.user },
});

const failure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  token: null,
});

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: success,
  [Types.LOGIN_FAILURE]: failure,
});
