import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  > label {
    padding-left: 5px;
    font-size: 15px;
    font-weight: 600;
  }
`;

function CheckBox({ isChecked, label, changeValue, onClick, id, ...props }) {
  return (
    <Container onClick={onClick}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={({ target: { checked } }) => changeValue(checked)}
        {...props}
      />
      <label htmlFor={id} style={{ cursor: 'pointer' }}>
        {label}
      </label>
    </Container>
  );
}

CheckBox.propTypes = {
  isChecked: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  changeValue: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

CheckBox.defaultProps = {
  isChecked: false,
  id: 'active',
  label: 'Ativo',
  onClick: () => {},
};

export default CheckBox;
