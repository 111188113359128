import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import Box from '../../components/BoxFilter';
import Card from '../../components/cards/cardListTracker';
import MaskedInput from '../../components/inputs/maskedInput';
import SimpleSelect from '../../components/selects/simpleSelect';
import TableComponent from '../../components/Table';
import { Creators as TrackerActions } from '../../store/ducks/trackerSauce';
import { ContainerTable } from './styles';

function Table({ history, height }) {
  const trackers = useSelector(state => state.trackerState.trackers);
  const loading = useSelector(state => state.trackerState.loading);
  const clienId = localStorage.getItem('clientId')
  const dispatch = useDispatch();
  useEffect(() => {
    if (clienId)
    dispatch(TrackerActions.fetchTrackerRequest(`?id_clients=[${clienId}]`));
  }, [dispatch])

  // useEffect(() => {
  //   console.log(trackers)
  // }, [trackers])

  const formik = useFormik({
    initialValues: {
      plate: '',
      active: true,
    },
    onSubmit: data => {
      const { plate, active } = data;
      dispatch(TrackerActions.fetchFilter(plate.toUpperCase(), active));
    },
  });
  const [widthPage, setWidth] = useState(window.innerWidth);

  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <ContainerTable>
        {widthPage <= 700 &&
      <Box columns={2}>
        <form onSubmit={formik.handleSubmit}>
          <MaskedInput
            mask="aaa-9*99"
            plate
            placeholder="filtrar por placa"
            name="plate"
            onChange={formik.handleChange}
            value={formik.values.plate}
          />
          <SimpleSelect
            placeholder="filtrar por status"
            defaultValue={
              formik.values.active
                ? { label: 'Ativo', value: true }
                : { label: 'Inativo', value: false }
            }
            options={[
              { label: 'Ativo', value: true },
              { label: 'Inativo', value: false },
            ]}
            onChange={option => formik.setFieldValue('active', option.value)}
          />
          <button type="subbmit">
            <MdSearch size={20} color="#027ab7" />
          </button>
        </form>
      </Box>
      }
      <div style={{ height }}>
        {widthPage <= 700 ? (
          <Card
            data={trackers}
            loading={loading}
            changeValue={data => history.push(`/tracker/form/${data.id}`)}
          />
        ) : (
          <Box>
            <TableComponent
              data={trackers.map(item => ({
                ...item,
                active: item.active ? 'Ativo' : 'Inativo',
              }))}
              fileTitle="Relatorio de item de rastreamento"
              name="veículos"
              headers={['plate', 'label', 'color', 'active', 'year']}
              labels={['placa', 'Identificador', 'Cor', 'Status', 'Ano']}
              getData={data => history.push(`/tracker/form/${data.id}`)}
            />
          </Box>
        )}
      </div>
    </ContainerTable>
  );
}

Table.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  height: PropTypes.string,
};

Table.defaultProps = {
  height: '200px',
};

export default Table;
