import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 30000,
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const setting = config;
  if (token) setting.headers.Authorization = token;
  return setting;
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
        localStorage.clear();
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
