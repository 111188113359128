import styled, { css } from 'styled-components';

const Container = styled.div`
  display: grid;
  max-height: 100%;
  grid-template-rows: 50px 1fr;
  grid-gap: 10px;
  margin: 10px;
  @media screen and (max-width: 700px) {
    ${props =>
      props.permission &&
      css`
        grid-template-rows: 120px 1fr;
      `}
  }
`;

export default Container;
