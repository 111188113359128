import axios from './axios';

axios.interceptors.request.use(
  function(config) {
    config.headers.adm = true;
    const clientId = localStorage.getItem('clientId');
    if (clientId) {
      config.headers.clientId = clientId;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default {
  login: user => axios.post('/auth/login', user),
  // tracker
  fetchTracker: data => axios.get(`/trackers${data || ''}`),
  updateTracker: data => axios.put(`/trackers/${data.id}`, data),
  createTracker: data => axios.post('/trackers', data),
  fetchTrackerByClient: id => axios.get(`/trackers${id || ''}`),
  getLastPositions: (imei, plate) =>
    axios.get(`/lastpositions/${imei}/${plate}`),

  // client
  fetchClient: id => axios.get(`/clients${id || ''}`),
  createClient: data => axios.post('/clients', data),
  updateClient: data => axios.put(`/clients/${data.id}`, data),

  // secretary
  fetchSecretary: data => axios.get(`/secretaries${data || ''}`),
  updateSecretary: data => axios.put(`/secretaries/${data.id}`, data),
  createSecretary: data => axios.post('/secretaries', data),
  fetchSecretaryByClient: id => axios.get(`/secretaries${id || ''}`),

  // type
  fetchType: id => axios.get(`/types${id || ''}`),
  createType: data => axios.post('/types', data),
  updateType: (data, id) => axios.put(`/types/${id}`, data),

  // user
  createUser: data => axios.post('/users', data),
  updateUser: data => axios.put(`/users/${data.id}`, data),
  fetchUser: id => axios.get(`/users${id || ''}`),
  fetchUserByClient: id => axios.get(`/users${id || ''}`),

  // permission
  fetchPermission: id => axios.get(`/permissions${id || ''}`),
  updatePermission: data => axios.post('/permissions', data),

  fetchDriverPermission: id => axios.get(`/drivers/permissions${id || ''}`),
  updateDriverPermission: data => axios.post('/drivers/permissions', data),

  // devices
  fetchImei: imei => axios.get(`/imei${imei || ''}`),
  fetchDevice: id => axios.get(`devices${id || ''}`),
  fetchAvailableDevices: () => axios.get(`devices?available=true`),
  createDevice: data => axios.post('/devices', data),
  updateDevice: data => axios.put(`/devices/${data.imei}`, data),

  //  driver
  fetchDrivers: clientId => axios.get(`/drivers${clientId || ''}`),

  // reports
  fetchReportTrackerByClient: clientId =>
    axios.get(`/trackerByClients/${clientId}`),

  // news
  createNews: data => axios.post('/news', data), // C do crud
  fetchNews: () => axios.get('/news'), // R do crud
  fetchNewsById: id => axios.get(`/news/${id}`), // R do crud
  updateNews: data => axios.put(`/news/${data.id}`, data), // U do crud
  deleteNews: id => axios.delete(`/news/${id}`), // D do crud
};
