import styled from 'styled-components';

const simpleField = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
     div {
      font-size: 16px;
      font-weight: 600;
      color: ${props => {
        if (props.error) {
          return 'rgba(257,0,0, 0.7)';
        }
        if (props.color) {
          return props.color;
        }
        return '#333';
      }};
      a {
      font-weight: 400;
      }
    }
  }
  span {
    color: red;
    font-size: 12px;
  }
`;

export default simpleField;
