import arraySort from 'array-sort';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { CalendarContainer } from 'react-datepicker';
import { AiFillPushpin } from 'react-icons/ai';
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdClose,
  MdExpandMore,
  MdExpandLess,
} from 'react-icons/md';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import InputMask from 'react-input-mask';
import Skeleton from 'react-loading-skeleton';

import csvLogo from './assets/csv.svg';
import pdfLogo from './assets/pdf.svg';
import exportCsv from './csv';
import exportPdf from './pdf';
import { numberSort, dateSort } from './sort';
import {
  Container,
  Header,
  HeaderItem,
  Detail,
  DetailRow,
  DetailItem,
  GroupItem,
  Footer,
  NumberContainer,
  DateFilterStyled,
} from './styles';

function Table({
  data,
  headers,
  labels,
  fix,
  headerBackground,
  headerColor,
  ColumnsWidth,
  loading,
  align,
  fileTitle,
  stripedColor,
  getData,
  dimensionHeight,
  filterTypes,
  groupType,
  filters,
  filterFunction,
}) {
  const [dataTable, setDataTable] = useState(data || []);
  const [dataImutable, setDataImutable] = useState(data || []);
  const [dataGroupImutable, setDataGroupImutable] = useState(data || []);
  const [headerTable, setHeaderTable] = useState(headers || []);
  const [labelsTable, setLabelsTable] = useState(labels || []);
  const [widthTable, setWidthTable] = useState(ColumnsWidth || []);
  const [isGrouped, setIsGrouped] = useState(false);
  const [groupSelected, setGroupSelected] = useState(false);
  const [showGroupIndex, setShowGroupIndex] = useState(false);
  const [headerSelected, setHeaderSelected] = useState({
    name: '',
    asc: false,
  });
  const [numberSignal, setNumberSignal] = useState(3);
  const [dateSelected, setDateSelected] = useState();
  const [loadingScroll, setLoadingScroll] = useState(false);
  const [isSearchable, setIsSearchable] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setDataTable([...data]);
    setDataImutable([...data]);
    setDataGroupImutable([...data]);
    setPage(1);
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(dataImutable)) {
      setDataTable(data.slice(0, 50));
      setPage(2);
    }
  }, [dataImutable]);

  useEffect(() => {
    setHeaderTable([...headers]);
  }, [headers]);

  useEffect(() => {
    setLabelsTable([...labels]);
  }, [labels]);
  async function sortInput(text, header) {
    if (isGrouped) {
      const newData = dataGroupImutable.map(item => ({
        [groupSelected]: item[groupSelected],
        value: matchSorter(item.value, text, { keys: [header] }),
      }));
      setDataTable([...newData]);
    } else if (text !== undefined) {
      setIsSearchable(true);
      if (filterFunction) {
        const newData = await filterFunction(
          text,
          dataImutable,
          header,
          filterTypes
        );
        setDataTable([...newData]);
      } else {
        const newData = await matchSorter(dataImutable, text, {
          keys: [header],
        });
        setDataTable([...newData]);
      }
    } else {
      setDataTable([...dataImutable]);
      setIsSearchable(false);
    }
  }

  function sortDate(date, header) {
    if (isGrouped) {
      if (date === null) {
        setIsSearchable(false);
        setDataTable([...dataGroupImutable]);
      } else {
        setIsSearchable(true);
        const newData = dataGroupImutable.map(item => ({
          [groupSelected]: item[groupSelected],
          value: dateSort({ arr: item.value, date, header }),
        }));
        setDataTable([...newData]);
      }
    } else if (date === null) {
      setIsSearchable(false);
      setDataTable([...dataImutable]);
    } else {
      setIsSearchable(true);
      const newData = dateSort({ arr: dataImutable, date, header });
      setDataTable([...newData]);
    }
  }

  async function sortNumer(number, type, header) {
    if (isGrouped) {
      if (number === undefined) {
        setIsSearchable(false);
        setDataTable([...dataGroupImutable]);
      } else if (number === undefined) {
        setIsSearchable(false);
        setDataTable([...dataImutable]);
      } else {
        setIsSearchable(true);
        const newData = dataGroupImutable.map(item => ({
          [groupSelected]: item[groupSelected],
          value: numberSort({
            arr: item.value,
            number,
            type,
            header,
          }),
        }));
        setDataTable([...newData]);
      }
    } else if (number === undefined) {
      setIsSearchable(false);
      setDataTable([...dataImutable]);
    } else {
      setIsSearchable(true);
      const newData = numberSort({
        arr: dataImutable,
        number,
        type,
        header,
      });
      setDataTable([...newData]);
    }
  }

  function getNumberSignal() {
    if (numberSignal === 1) {
      return '≤';
    }
    if (numberSignal === 2) {
      return '=';
    }
    return '≥';
  }

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ width: '328px' }}>
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  function getType(type, labelsTable, index, header) {
    if (type === 'string') {
      return (
        <input
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
          placeholder={`Pesquise por ${labelsTable[index]}`}
          onChange={e => sortInput(e.target.value || undefined, header)}
        />
      );
    }
    if (type === 'number') {
      return (
        <NumberContainer
          headerColor={headerColor}
          headerBackground={headerBackground}
        >
          <input
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
            type="number"
            placeholder={`Pesquise por ${
              labelsTable[index]
            } ${getNumberSignal()}`}
            onChange={e =>
              sortNumer(e.target.value || undefined, numberSignal, header)
            }
          />
          <button
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              if (numberSignal === 1) {
                setNumberSignal(2);
              } else if (numberSignal === 2) {
                setNumberSignal(3);
              } else if (numberSignal === 3) {
                setNumberSignal(1);
              }
            }}
          >
            {getNumberSignal()}
          </button>
        </NumberContainer>
      );
    }
    if (type === 'date') {
      return (
        <DateFilterStyled
          headerColor={headerColor}
          headerBackground={headerBackground}
          isClearable
          // showTimeSelect
          selected={dateSelected}
          dateFormat="dd/MM/yyyy"
          onChange={value => {
            setDateSelected(value);
            sortDate(value, header);
          }}
          locale={pt}
          customInput={<InputMask mask="99/99/9999" />}
          calendarContainer={MyContainer}
          placeholderText={`Pesquise por ${labelsTable[index]}`}
        />
      );
    }
    return (
      <input
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
        placeholder={`Pesquise por ${labelsTable[index]}`}
        onChange={e => sortInput(e.target.value || undefined, header)}
      />
    );
  }

  function sortHeader(header) {
    if (headerSelected.name === header) {
      setHeaderSelected({ name: header, asc: !headerSelected.asc });
    } else {
      setHeaderSelected({ name: header, asc: true });
    }
    if (isGrouped) {
      const newData = dataTable.map(item => {
        const json = {
          [groupSelected]: item[groupSelected],
          value: arraySort(item.value, header, {
            reverse: headerSelected.asc,
          }),
        };
        headers.map(
          (teste, index) =>
            teste != groupSelected &&
            (json[teste] = getGroupType(groupType[index], item.value, teste))
        );
        return json;
      });
      setDataTable([...newData]);
      setDataGroupImutable([...newData]);
    } else {
      const newData = arraySort(data, header, {
        reverse: headerSelected.asc,
      });
      // setDataTable([...newData]);
      setDataImutable([...newData]);
      setDataGroupImutable([...newData]);
      setDataTable([...newData.slice((page - 1) * 50, page * 50)]);
      setPage(1);
    }
  }

  function exportTablePdf() {
    let arr = [];
    if (isGrouped) {
      dataTable.map(item => {
        item.value.map((teste, index) => {
          const newArr = [];
          headers.map((i, a) => {
            if (index === 0) {
              if (i == groupSelected) {
                newArr.push({
                  rowSpan: i == groupSelected ? item.value.length + 1 : 1,
                  content: teste[i],
                  styles: {
                    valign: 'middle',
                    halign: 'center',
                  },
                });
              } else {
                newArr.push(teste[i]);
              }
            } else if (i !== groupSelected) {
              newArr.push(teste[i]);
            }
          });
          return (arr = [...arr, newArr]);
        });
        const newFooter = [];
        headers.map(i => {
          if (i !== groupSelected) {
            newFooter.push({
              content: item[i],
            });
          }
        });
        arr = [...arr, newFooter];
      });
    } else if (isSearchable) {
      arr = dataTable.map(item => headers.map(i => [item[i]]));
    } else {
      arr = dataImutable.map(item => headers.map(i => [item[i]]));
    }
    exportPdf(
      arr,
      headers,
      labels,
      fileTitle,
      headerBackground,
      headerColor,
      format(new Date(), 'dd/MM/yyyy HH:mm'),
      isGrouped,
      filters
    );
  }

  function exportTableCsv() {
    exportCsv(
      dataImutable.map(item => headers.map(i => [item[i]])),
      labels,
      `${fileTitle} ${format(new Date(), 'dd-MM-yyyy HH:mm:ss')}`
    );
  }

  function getGroupType(group, value, name) {
    if (group === 'simple') {
      return ' ';
    }
    if (group === 'sum') {
      return value
        .map(item => item[name])
        .reduce(function(acumulador, valorAtual) {
          return acumulador + valorAtual;
        }, 0)
        .toFixed(3);
    }
    if (group === 'avg') {
      return (
        value
          .map(item => item[name])
          .reduce(function(acumulador, valorAtual) {
            return acumulador + valorAtual;
          }, 0)
          .toFixed(3) / value.length
      ).toFixed(3);
    }
    return '-';
  }

  async function groupHeader(e, header) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (groupSelected === header) {
      setDataTable([...dataImutable]);
      setDataGroupImutable([...dataImutable]);
      setIsGrouped(false);
      setGroupSelected(false);
      setShowGroupIndex(false);
    } else {
      const indexArr = headerTable.findIndex(item => item === header);
      headers.splice(0, 0, headers.splice(indexArr, 1)[0]);
      labels.splice(0, 0, labels.splice(indexArr, 1)[0]);
      groupType.splice(0, 0, groupType.splice(indexArr, 1)[0]);
      filterTypes.splice(0, 0, filterTypes.splice(indexArr, 1)[0]);
      ColumnsWidth.splice(0, 0, ColumnsWidth.splice(indexArr, 1)[0]);
      setHeaderTable([...headers]);
      setLabelsTable([...labels]);
      setWidthTable([...ColumnsWidth]);
      const newArr = _.chain(dataImutable)
        .groupBy(header)
        .map((value, key) => {
          const json = { [header]: key, value };
          headers.map(
            (item, index) =>
              item != header &&
              (json[item] = getGroupType(groupType[index], value, item))
          );
          return json;
        })
        .value();
      setDataTable([...newArr]);
      setDataGroupImutable([...newArr]);
      setIsGrouped(true);
      setGroupSelected(header);
    }
  }

  async function handleLoadMore() {
    if (isGrouped || isSearchable) {
      // console.log('ja está agrupado/pesquisando');
    } else {
      await setLoadingScroll(true);

      await setDataTable([
        ...dataTable,
        ...dataImutable.slice((page - 1) * 50, page * 50),
      ]);
      await setPage(page + 1);
      await setLoadingScroll(false);
    }
    // setLoading(true);
    // // Some API call to fetch the next page
    // loadNextPage(endCursor, pageSize).then((newPage) => {
    //   setLoading(false);
    //   setHasNextPage(newPage.hasNextPage);
    //   setItems([...items, newPage.items]);
    // });
  }

  const infiniteRef = useInfiniteScroll({
    loading: loadingScroll,
    hasNextPage: true,
    onLoadMore: handleLoadMore,
    scrollContainer: 'parent',
  });

  return (
    <Container style={{ height: dimensionHeight }}>
      <Header
        fix={fix}
        headerBackground={headerBackground}
        headerColor={headerColor}
        columns={headerTable.length}
        isGrouped={isGrouped}
        isWidth={_.isEmpty(widthTable)}
      >
        {isGrouped && <GroupItem />}
        {headerTable.map((header, index) => (
          <HeaderItem
            key={`headerItem_${header}`}
            headerWidth={() => {
              if (_.isEmpty(widthTable)) return false;
              if (widthTable.length !== headerTable.length) return false;
              return widthTable[index];
            }}
            align={align}
            headerBackground={headerBackground}
            headerColor={headerColor}
          >
            <div onClick={() => sortHeader(header)}>
              <span>
                {labelsTable && labelsTable[index]}
                {headerSelected.name === header &&
                  (headerSelected.asc ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  ))}
              </span>
              <button type="button" onClick={e => groupHeader(e, header)}>
                {groupSelected === header ? (
                  <MdClose color={headerColor} size={15} />
                ) : (
                  <AiFillPushpin color={headerColor} size={15} />
                )}
              </button>
            </div>
            {_.isEmpty(filterTypes) ? (
              <input
                onClick={e => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                }}
                placeholder={`Pesquise por ${labelsTable[index]}`}
                onChange={e => sortInput(e.target.value || undefined, header)}
              />
            ) : (
              getType(filterTypes[index], labelsTable, index, header)
            )}
          </HeaderItem>
        ))}
      </Header>
      <Detail>
        {loading ? (
          <Skeleton count={10} height={30} />
        ) : _.isEmpty(dataTable) ? (
          <div>Sem Registro</div>
        ) : (
          <div ref={infiniteRef}>
            {dataTable.map((item, i) => (
              <>
                <DetailRow
                  key={i}
                  columns={headerTable.length}
                  isWidth={_.isEmpty(widthTable)}
                  isGrouped={isGrouped}
                  stripedColor={stripedColor}
                  onClick={() => !isGrouped && getData(item, i)}
                  cursor={isGrouped}
                >
                  {isGrouped && (
                    <GroupItem>
                      {showGroupIndex === i ? (
                        <MdExpandLess
                          onClick={() => setShowGroupIndex(false)}
                        />
                      ) : (
                        <MdExpandMore onClick={() => setShowGroupIndex(i)} />
                      )}
                    </GroupItem>
                  )}
                  {_.isEmpty(headerTable) ? (
                    <DetailItem>A</DetailItem>
                  ) : (
                    headerTable.map((header, index) => (
                      <DetailItem
                        key={index}
                        detailWidth={() => {
                          if (_.isEmpty(widthTable)) return false;
                          if (widthTable.length !== headerTable.length)
                            return false;
                          return widthTable[index];
                        }}
                        align={align}
                      >
                        {item[header]
                          ? item[header].toString()
                          : item.value
                          ? item.value.length
                          : '-'}
                      </DetailItem>
                    ))
                  )}
                </DetailRow>
                {showGroupIndex === i &&
                  item.value.map((teste, i) => (
                    <DetailRow
                      key={i}
                      columns={headerTable.length}
                      isWidth={_.isEmpty(widthTable)}
                      isGrouped={isGrouped}
                      stripedColor={stripedColor}
                      onClick={() => getData(teste, i)}
                      cursor={!isGrouped}
                    >
                      <GroupItem />
                      {headerTable.map((header, index) => (
                        <DetailItem
                          key={index}
                          detailWidth={() => {
                            if (_.isEmpty(widthTable)) return false;
                            if (widthTable.length !== headerTable.length)
                              return false;
                            return widthTable[index];
                          }}
                          align={align}
                        >
                          {teste[header] ? teste[header].toString() : '-'}
                        </DetailItem>
                      ))}
                    </DetailRow>
                  ))}
              </>
            ))}
          </div>
        )}
      </Detail>
      <Footer footerBackground={headerBackground} footerColor={headerColor}>
        <span>
          <strong>&nbsp; {dataImutable.length} registro(s)</strong>
          {/* <small>{isGrouped && `Agrupado por: ${groupSelected}`}</small> */}
        </span>
        <div>
          <button type="button" onClick={() => exportTablePdf()}>
            <img src={pdfLogo} alt="PDF LOGO" />
          </button>
          <button type="button" onClick={() => exportTableCsv()}>
            <img src={csvLogo} alt="CSV LOGO" />
          </button>
        </div>
      </Footer>
    </Container>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  headers: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  ColumnsWidth: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  fix: PropTypes.bool,
  headerBackground: PropTypes.string,
  headerColor: PropTypes.string,
  align: PropTypes.string,
  fileTitle: PropTypes.string,
  stripedColor: PropTypes.string,
  getData: PropTypes.func.isRequired,
  dimensionHeight: PropTypes.string,
  filterTypes: PropTypes.arrayOf(PropTypes.string),
  groupType: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.string,
  filterFunction: PropTypes.func,
};

Table.defaultProps = {
  loading: false,
  fix: true,
  data: [],
  filterTypes: [],
  groupType: [],
  headers: [],
  labels: [],
  ColumnsWidth: [],
  headerBackground: '#235277',
  headerColor: '#f2f1f2',
  align: 'flex-start',
  fileTitle: 'Export',
  stripedColor: '#e9edf1',
  dimensionHeight: '500px',
  filters: '',
  filterFunction: null,
};

export default Table;
