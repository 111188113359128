import Select from 'react-select';
import styled from 'styled-components';

const simpleSelect = styled(Select)`
  width: 100%;
  font-weight: 300;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.01);
  border: none;
  border-radius: 500px;
  height: 35px;
  margin: 5px auto;
  cursor: pointer;
  .css-yk16xz-control {
    width: 100%;
    border: none;
    border-radius: 500px;
    background: rgba(0, 0, 0, 0.001);
    color: #333;
    border: 1px solid ${props => (props.error ? 'rgba(257,0,0, 1)' : '#ddd')};
  }
  .css-1pahdxg-control {
    width: 100%;
    border: none;
    border-radius: 500px;
    background: rgba(0, 0, 0, 0.001);
    color: #333;
    border: 1px solid ${props => (props.error ? 'rgba(257,0,0, 1)' : '#ddd')};
  }
`;

export default simpleSelect;
