import { Formik, useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import React, { useEffect, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from './styles';


import { Creators as PermissionActions } from '../../store/ducks/permissionSauce';
import 'rc-collapse/assets/index.css';
import FloatButton from '../../components/buttons/floatButton';
import { ContainerUser } from './styles';

export default function Permission({ height }) {
  const dispatch = useDispatch();
  const trackers = useSelector(state => state.trackerState.trackerClient);
  const permissions = useSelector(state => state.permissionState.permissions);
  const loading = useSelector(state => state.permissionState.loading);
  const formik = useFormik({
    initialValues: {
      cars: [],
    },
    onSubmit: data => {
      let arr = [];
      let arrNotChecked = [];
      data.cars.forEach(client => {
        arr = arr.concat(
          client.tracker.filter(tracker => tracker.checked).map(item => item.id)
        );
        arrNotChecked = arrNotChecked.concat(
          client.tracker
            .filter(tracker => !tracker.checked)
            .map(item => item.id)
        );
      });
      const teste = _.difference(permissions.id_trackers, arrNotChecked);
      permissions.id_trackers = _.union(teste, arr);
      dispatch(PermissionActions.updatePermissionRequest(permissions));
    },
  });
  useEffect(() => {
    if (!_.isEmpty(trackers)) {
      const trackersGrouped = _.chain(trackers)
        .groupBy('client.name')
        .map((item, key) => ({
          client: key,
          tracker: item.map(tracker => ({
            ...tracker,
            checked: _.isEmpty(permissions)
              ? false
              : !!permissions.id_trackers.includes(tracker.id),
          })),
          clientId: item[0].client.id,
        }))
        .value();
      // const usersGrouped = _.groupBy(users, 'client.name');
      formik.setValues({ cars: trackersGrouped });
    } else {
      formik.setValues({ cars: [] });
    }
  }, [trackers, permissions]);

  function inverter (clientId) {
    formik.setFieldValue('cars', formik.values.cars.map((car) => {
      if (car.clientId === clientId) {
        car.tracker = car.tracker.map(t => {
          t.checked = !t.checked
          return t
        })
      }
      return car
    }))
  }

    function selectAll (clientId) {
      formik.setFieldValue('cars', formik.values.cars.map((car) => {
        if(car.clientId === clientId) {
          car.tracker = car.tracker.map(t => {
            t.checked = true
            return t
          })
        }
        return car
      }))
    }
  return (
    <form onSubmit={formik.handleSubmit} style={{ height, overflow: 'scroll' }}>
      <Collapse accordion style={{ height: '100%' }}>
        {!_.isEmpty(permissions) &&
          !_.isEmpty(formik.values.cars) &&
          formik.values.cars.map((item, indexClient) => {
            return (
              <Panel header={item.client} key={item.clientId}>
                <Button type="button" onClick={() => selectAll(item.clientId)}>Selecionar tudo</Button>
                <Button type="button" onClick={() => inverter(item.clientId)}>Inverter seleção</Button>
                {_.isEmpty(item.tracker)
                  ? 'Não possui carro cadastrado'
                  : item.tracker.map((tracker, indexTracker) => {
                      return (
                        <ContainerUser key={tracker.id}>
                          <div className="pretty p-icon p-round p-smooth">
                            <input
                              type="checkbox"
                              id="check"
                              checked={tracker.checked}
                              onChange={event =>
                                formik.setFieldValue(
                                  `cars[${indexClient}].tracker[${indexTracker}].checked`,
                                  event.target.checked
                                )
                              }
                              value={
                                formik.values.cars[indexClient].tracker[
                                  indexTracker
                                ].checked
                              }
                            />
                            <div className="state p-info">
                              <MdCheck className="icon" />
                              <label htmlFor="check">{tracker.plate}</label>
                            </div>
                          </div>
                        </ContainerUser>
                      );
                    })}
              </Panel>
            );
          })}
      </Collapse>
      {!_.isEmpty(permissions) && (
        <FloatButton type="subbmit" Icon={MdCheck} loading={loading} />
      )}
    </form>
  );
}

Permission.propTypes = {
  height: PropTypes.string,
};

Permission.defaultProps = {
  height: '200px',
};
