import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';
import { useDispatch, useSelector } from 'react-redux';

import devices from '../../assets/json/devices.json';
import inputs from '../../assets/json/inputs.json';
import outputs from '../../assets/json/outputs.json';
import LoadingDefault from '../../components/animations/loadingAnimationDefault';
import Box from '../../components/Box';
import SimpleButton from '../../components/buttons/simpleButton';
import ContainerForm from '../../components/containers/containerForm';
import SelectField from '../../components/fields/selectField';
import SimpleField from '../../components/fields/simpleField';
import HeaderCardForm from '../../components/headerCardForm';
import MaskedInput from '../../components/inputs/maskedInput';
import SimpleInput from '../../components/inputs/simpleInput';
import Table from '../../components/Table';
import api from '../../services/api';
import { Creators as deviceActions } from '../../store/ducks/deviceSauce';
// import { Container } from './styles';

function Device({ match, history }) {
  const device = useSelector(state => state.deviceState.device);
  const loading = useSelector(state => state.deviceState.loading);
  const dispatch = useDispatch();
  const [port, setPort] = useState([false, false, false, false, false, false]);
  const [isNew, setIsNew] = useState(false);
  const [modal, setModal] = useState(false);
  const [lastPositions, setLastPositions] = useState([]);
  const formik = useFormik({
    initialValues: {
      imei: '',
      phone: '',
      model: '',
      type: '',
      input1: '',
      input2: '',
      input3: '',
      output1: '',
      output2: '',
      output3: '',
    },
    validate: values => {
      const err = {};
      const message = 'Campo obrigatório!';
      if (!values.imei) err.imei = message;
      if (!values.phone) err.phone = message;
      if (!values.model) err.model = message;
      if (!values.type) err.type = message;

      return err;
    },
    onSubmit: data => {
      dispatch(deviceActions.updateDeviceRequest(data));
    },
  });

  useEffect(() => {
    if (match.params.id) {
      dispatch(deviceActions.fetchDeviceRequest(`/${match.params.id}`));
    } else {
      formik.setValues({ ...formik.values });
    }
  }, [match.params.id, dispatch]);
  useEffect(() => {
    if (!_.isEmpty(device)) {
      setIsNew(device.model === null);
      formik.setValues({ ...device });
    }
  }, [device]);
  useEffect(() => {
    switch (formik.values.type) {
      case '340':
        setPort([true, true, true, true, true, false]);
        break;
      case '340RB':
        setPort([true, true, false, true, false, false]);
        break;
      case 'ST310U':
        setPort([true, false, false, true, false, false]);
        break;
      case 'ST310UC2':
        setPort([true, false, false, true, false, false]);
        break;
      case 'ST4315U':
        setPort([true, false, false, true, false, false]);
        break;
      case '300HD':
        setPort([true, true, false, true, true, false]);
        break;
      case 'GV75':
        setPort([true, false, false, true, false, false]);
        break;
      case 'NT20':
        setPort([false, false, false, true, false, false]);
        break;
      case 'VIT4100':
        setPort([false, false, false, true, false, false]);
        break;
      default:
        setPort([false, false, false, false, false, false]);
        break;
    }
  }, [formik.values.type]);

  async function loadLastPositions(imei, plate) {
    try {
      const response = await api.getLastPositions(imei, plate);
      if (response.status === 200) {
        setLastPositions(response.data);
        setModal(true);
      }
    } catch (err) {
      if (err.response.status === 404) {
        // eslint-disable-next-line no-alert
        alert('Não há dados');
        setModal(false);
      }
    }
  }

  return (
    <>
      <ContainerForm onSubmit={formik.handleSubmit}>
        <HeaderCardForm
          title="Formulário de Dispositivo"
          route="/device"
          history={history}
          loading={loading}
        />
        {loading ? (
          <LoadingDefault />
        ) : (
          <Box>
            <SimpleField>
              <div>
                Carro vinculado
                <a
                  href={`/tracker/form/${!_.isEmpty(device) &&
                    device.TrackerItem &&
                    device.TrackerItem.id}`}
                  title="ir para o formulario de edicao do carro"
                >
                  {' '}
                  editar{' '}
                </a>
              </div>
              <SimpleInput
                name="plate"
                value={
                  _.isEmpty(device)
                    ? 'sem carro vinculado'
                    : device.TrackerItem
                    ? device.TrackerItem.plate
                    : 'sem carro vinculado'
                }
                disabled
              />
            </SimpleField>
            <SimpleField>
              <div>IMEI</div>
              <SimpleInput
                name="imei"
                onChange={formik.handleChange}
                value={formik.values.imei}
                error={formik.touched.imei && formik.errors.imei}
                disabled
              />
            </SimpleField>
            <SimpleField>
              <div>Telefone</div>
              <MaskedInput
                mask="(99)9 9999-9999"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                error={formik.touched.phone && formik.errors.phone}
              />
            </SimpleField>
            {(formik.values.model || isNew) && (
              <SelectField
                title="Marca"
                placeholder="Selecione uma marca"
                options={devices}
                defaultValue={
                  devices[
                    devices.findIndex(
                      item => item.label === formik.values.model
                    )
                  ]
                }
                changeValueSelect={option =>
                  formik.setFieldValue('model', option.label)
                }
              />
            )}
            {match.params.id && formik.values.model && (
              <SelectField
                title="Modelo"
                placeholder="Selecione um modelo"
                options={
                  devices[
                    devices.findIndex(
                      item => item.label === formik.values.model
                    )
                  ].models
                }
                defaultValue={
                  devices[
                    devices.findIndex(
                      item => formik.values.model === item.label
                    )
                  ].models[
                    devices[
                      devices.findIndex(
                        item => formik.values.model === item.label
                      )
                    ].models.findIndex(
                      item => formik.values.type === item.label
                    )
                  ]
                }
                changeValueSelect={option =>
                  formik.setFieldValue('type', option.value)
                }
              />
            )}
            {port[0] && (
              <SelectField
                title="Input 1"
                placeholder="Selecione um input"
                options={inputs}
                defaultValue={
                  inputs[
                    inputs.findIndex(item => item.value == formik.values.input1)
                  ]
                }
                changeValueSelect={option =>
                  formik.setFieldValue('input1', option ? option.value : '')
                }
              />
            )}
            {port[1] && (
              <SelectField
                title="Input 2"
                placeholder="Selecione um input"
                options={inputs}
                defaultValue={
                  inputs[
                    inputs.findIndex(item => item.value == formik.values.input2)
                  ]
                }
                changeValueSelect={option =>
                  formik.setFieldValue('input2', option ? option.value : '')
                }
              />
            )}
            {port[2] && (
              <SelectField
                title="Input 3"
                placeholder="Selecione um input"
                options={inputs}
                defaultValue={
                  inputs[
                    inputs.findIndex(item => item.value == formik.values.input3)
                  ]
                }
                changeValueSelect={option =>
                  formik.setFieldValue('input3', option ? option.value : '')
                }
              />
            )}
            {port[3] && (
              <SelectField
                title="Output 1"
                placeholder="Selecione um output"
                options={outputs}
                defaultValue={
                  outputs[
                    outputs.findIndex(
                      item => item.value == formik.values.output1
                    )
                  ]
                }
                changeValueSelect={option =>
                  formik.setFieldValue('output1', option ? option.value : '')
                }
              />
            )}
            {port[4] && (
              <SelectField
                title="Output 2"
                placeholder="Selecione um output"
                options={outputs}
                defaultValue={
                  outputs[
                    outputs.findIndex(
                      item => item.value == formik.values.output2
                    )
                  ]
                }
                changeValueSelect={option =>
                  formik.setFieldValue('output2', option ? option.value : '')
                }
              />
            )}
            {port[5] && (
              <SelectField
                title="Output 3"
                placeholder="Selecione um output"
                options={outputs}
                defaultValue={
                  outputs[
                    outputs.findIndex(
                      item => item.value == formik.values.output3
                    )
                  ]
                }
                changeValueSelect={option =>
                  formik.setFieldValue('output3', option ? option.value : '')
                }
              />
            )}
            <SimpleButton
              text="carregar últimas posições enviadas"
              onClick={() =>
                loadLastPositions(formik.values.imei, device.TrackerItem.plate)
              }
            />
          </Box>
        )}
      </ContainerForm>
      <Modal visible={modal} width="1140" onClickAway={() => setModal(false)}>
        <Table
          data={lastPositions}
          name="ultimas posicoes"
          headers={['data']}
          labels={['Posição']}
          filterTypes={['string']}
          getData={() => []}
          filterFunction={(search, data) => {
            return data
              .filter(row => {
                if (row && row.data) {
                  return row.data.includes(search);
                }
                return false;
              })
              .sort((a, b) =>
                a.data && b.data && a.data.split(';')[0] > b.data.split(';')[0]
                  ? -1
                  : 1
              );
          }}
        />
      </Modal>
    </>
  );
}

Device.propTypes = {
  history: PropTypes.objectOf.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Device;
