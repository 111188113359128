import styled from 'styled-components';

export const UploadContainer = styled.div`
  padding: 0 5px;
  > input {
    display: none;
  }
  > label {
    @media screen and (max-width: 700px) {
      margin-top: 0;
    }
    margin-top: 32px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    background: #0991d1;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    :hover {
      opacity: 0.7;
    }
  }
`;
