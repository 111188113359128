import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import api from '../../services/api';
import { Types } from '../ducks/secretarySauce';

export function* requestSecretarySaga(payload) {
  try {
    const response = yield call(api.fetchSecretary, payload.data);
    if (_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_SECRETARY_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_SECRETARY_ID_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Types.FETCH_SECRETARY_FAILURE });
  }
}

export function* requestSecretaryByClientSaga(payload) {
  try {
    const response = yield call(
      api.fetchSecretaryByClient,
      `?id_clients=${JSON.stringify(payload.data)}`
    );
    yield put({
      type: Types.FETCH_SECRETARY_BY_CLIENT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_SECRETARY_FAILURE });
  }
}

export function* updateSecretarySaga(payload) {
  try {
    yield call(api.updateSecretary, payload.data);
    yield put({
      type: Types.UPDATE_SECRETARY_SUCCESS,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/secretary'));
  } catch (error) {
    yield put({ type: Types.FETCH_SECRETARY_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export function* createSecretarySaga(payload) {
  try {
    yield call(api.createSecretary, payload.data);
    yield put({
      type: Types.FETCH_SECRETARY_REQUEST,
    });
    toast.success('Secretaria cadastrada com sucesso!');
    yield put(push('/secretary'));
  } catch (error) {
    yield put({ type: Types.FETCH_SECRETARY_FAILURE });
    toast.warning('Erro ao cadastrar a secretaria');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_SECRETARY_REQUEST, requestSecretarySaga)]);
  yield all([takeEvery(Types.UPDATE_SECRETARY_REQUEST, updateSecretarySaga)]);
  yield all([takeEvery(Types.CREATE_SECRETARY_REQUEST, createSecretarySaga)]);
  yield all([
    takeEvery(Types.FETCH_SECRETARY_BY_CLIENT_REQUEST, requestSecretaryByClientSaga),
  ]);
}
