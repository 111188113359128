import PropTypes from 'prop-types';
import React from 'react';

import { Container, Description, Title } from './styles';

function CardListClient({ data, changeValue }) {
  return (
    <div>
      {data.map(item => (
        <Container key={item.id} onClick={() => changeValue(item)}>
          <Title>{item.imei}</Title>
          <Description>
            <div>{item.phone}</div>
            <div>{item.formatedModel}</div>
          </Description>
        </Container>
      ))}
    </div>
  );
}

CardListClient.propTypes = {
  data: PropTypes.arrayOf.isRequired,
  changeValue: PropTypes.func.isRequired,
};
export default CardListClient;
