import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import colors from '../../assets/json/colors.json';
import Animation from '../animations/loadingAnimationDefault';
import SelectField from './selectField';

function SelectColorField({ changeColorValue, error, defaultValue }) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const formatedColors = colors
      .map(color => ({ label: color.label, value: color.value }))
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
    setOptions(formatedColors);
  }, []);

  return (
    <>
      {_.isEmpty(options) ? (
        <Animation />
      ) : (
        <SelectField
          options={options}
          title="Cor"
          changeValueSelect={option => changeColorValue(option)}
          placeholder="Selecione uma cor"
          defaultValue={defaultValue}
          error={error}
        />
      )}
    </>
  );
}

SelectColorField.propTypes = {
  changeColorValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.bool,
};

SelectColorField.defaultProps = {
  defaultValue: {},
  error: false,
};

export default SelectColorField;
