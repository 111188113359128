import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AnimationLoading from './animationLoading';

const SimpleButton = styled.button.attrs(props => ({
  type: props.type ? props.type : 'button',
}))`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '40px')};
  border: none;
  padding: 0 10px;
  border-radius: 500px;
  font-weight: 600;
  font-size: ${props => (props.font ? props.font : '20px')};
  background: ${props => (props.bg ? props.bg : '#fff')};
  color: ${props => (props.color ? props.color : '#333')};

  cursor: ${props => {
    if (props.disabled) {
      return 'not-allowed';
    }
    if (props.loading) {
      return 'progress';
    }
    return 'pointer';
  }};
  :active {
    opacity: 0.5;
  }
`;

const ButtonContainer = styled.div`
  padding: 10px;
`;

function Button({ text, loading, ...props }) {
  return (
    <ButtonContainer>
      <SimpleButton loading={loading} {...props}>
        {loading ? <AnimationLoading /> : text}
      </SimpleButton>
    </ButtonContainer>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
};

export default Button;
