import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import swConfig from './swConfig';

ReactDOM.render(<App />, document.getElementById('root'));

// serviceWorker.register(); //
serviceWorker.register(swConfig);
