import styled from 'styled-components';

export const Container = styled.div`
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 4;

  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 70px;
  > div {
    border-radius: 10px 0 0 10px;
  }
  background: #f9fafd;
  @media screen and (max-width: 700px) {
    background: #3e3838;

    padding: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 0;
    border-radius: 0;
  }
  > div {
    background: #3e3838;
    border: 0;
  }

  form {
    border-radius: 0 10px 10px 0;
    background: #027ab7;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;
    @media screen and (max-width: 700px) {
      border-radius: 0;
      background: #3e3838;
    }
  }
`;

export const TitleContainer = styled.div`
  color: #fff;
  background: #027ab7;
  font-size: 30px;
  font-weight: 600;
  padding: 10px;
  border-radius: 0 15px 15px 0;
  margin: 17px 216px 0 0;
  @media screen and (max-width: 700px) {
    margin: 17px 40px 0 0;
  }
`;
