import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingDefault from '../../components/animations/loadingAnimationDefault';
import Box from '../../components/Box';
import ContainerForm from '../../components/containers/containerForm';
import SelectClientField from '../../components/fields/selectClientField';
import SimpleField from '../../components/fields/simpleField';
import HeaderCardForm from '../../components/headerCardForm';
import SimpleInput from '../../components/inputs/simpleInput';
import { Creators as SecretaryActions } from '../../store/ducks/secretarySauce';

function SecretaryForm({ history, match }) {
  const secretary = useSelector(state => state.secretaryState.secretary);
  const loading = useSelector(state => state.secretaryState.loading);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      client_id: Number(localStorage.getItem('clientId')),
      name: '',
      acronym: '',
      client: { name: '' },
    },
    validate: values => {
      const err = {};
      const message = 'Campo obrigatório!';
      if (!values.client_id) err.client_id = message;
      if (!values.name) err.name = message;
      if (!values.acronym) err.acronym = message;
      if (!values.client) err.client = message;
      return err;
    },
    onSubmit: data => {
      if (match.params.id) {
        dispatch(SecretaryActions.updateSecretaryRequest(data));
      } else {
        dispatch(SecretaryActions.createSecretaryRequest(data));
      }
    },
  });

  useEffect(() => {
    if (match.params.id) {
      dispatch(SecretaryActions.fetchSecretaryRequest(`/${match.params.id}`));
    } else {
      formik.setValues({ ...formik.values });
    }
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(secretary)) {
      formik.setValues({ ...secretary });
    }
  }, [secretary]);

  useEffect(() => {
    formik.resetForm();
  }, []);
  return (
    <ContainerForm onSubmit={formik.handleSubmit} enableReinitialize>
      <HeaderCardForm
        title="Cadastro de secretaria"
        route="/secretary"
        history={history}
        loading={loading}
      />
      {loading ? (
        <LoadingDefault />
      ) : (
        <Box secretary>
          <SimpleField>
            <div>Nome</div>
            <SimpleInput
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={!!(formik.touched.name && formik.errors.name)}
            />
          </SimpleField>
          <SimpleField>
            <div>Sigla</div>
            <SimpleInput
              name="acronym"
              onChange={formik.handleChange}
              value={formik.values.acronym}
              error={!!(formik.touched.acronym && formik.errors.acronym)}
            />
          </SimpleField>
          <SelectClientField
            changeClientValue={option =>
              formik.setFieldValue('client_id', option.value)
            }
            defaultValue={{
              label: formik.values.client.name,
              value: formik.values.client_id,
            }}
            error={!!(formik.touched.client_id && formik.errors.client_id)}
          />
        </Box>
      )}
    </ContainerForm>
  );
}

SecretaryForm.propTypes = {
  history: PropTypes.objectOf.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SecretaryForm;
