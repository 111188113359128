import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchUserRequest: ['data'],
  fetchUserByClientRequest: ['data'],
  fetchUserSuccess: ['data'],
  fetchUserIdSuccess: ['data'],
  fetchUserByClientSuccess: ['data'],
  fetchUserFailure: [],
  fetchUserClientClear: [],
  updateUserRequest: ['data'],
  createUserRequest: ['data'],
  fetchFilters: ['name'],
});

const INITIAL_STATE = {
  users: [],
  usersImutable: [],
  userClients: [],
  user: {},
  loading: false,
};

const fetchUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  users: [],
  user: {},
  loading: true,
});

const fetchUserByClientRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchUserSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  users: data,
  usersImutable: data,
  loading: false,
});

const fetchUserIdSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  user: actions.data,
  loading: false,
});
const fetchUserByClientSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  userClients: actions.data,
  loading: false,
});

const fetchUserFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    users: [],
  };
};

const fetchUserClientClear = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  userClients: [],
});
const updateUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const createUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchFilters = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    users: state.usersImutable.filter(user => {
      const { name } = user;
      if (name.toUpperCase().indexOf(actions.name.toUpperCase()) !== -1) {
        return user;
      }
    }),
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_USER_REQUEST]: fetchUserRequest,
  [Types.FETCH_USER_BY_CLIENT_REQUEST]: fetchUserByClientRequest,
  [Types.FETCH_USER_SUCCESS]: fetchUserSuccess,
  [Types.FETCH_USER_ID_SUCCESS]: fetchUserIdSuccess,
  [Types.FETCH_USER_BY_CLIENT_SUCCESS]: fetchUserByClientSuccess,
  [Types.FETCH_USER_FAILURE]: fetchUserFailure,
  [Types.FETCH_USER_CLIENT_CLEAR]: fetchUserClientClear,
  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.CREATE_USER_REQUEST]: createUserRequest,
  [Types.FETCH_FILTERS]: fetchFilters,
});
