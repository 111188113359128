import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 4;
  padding: 20px;
  background: #f9fafd;
  justify-content: center;
  align-content: center;
  grid-template-columns: 200px 200px 200px 200px;
  grid-template-rows: 150px 150px;
  grid-template-areas: 'car user client device permissionLogbook' 'type permission messages report news';
  grid-gap: 20px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 100px 100px 100px;
    grid-template-areas: 'car user' 'client device' 'permissionLogbook type ' 'permission messages ' 'report news ';
  }
  @media screen and (max-width: 885px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 100px 100px 100px;
    grid-template-areas: 'car user' 'client device' 'permissionLogbook type ' 'permission messages ' 'report news ';
  }
`;

export const Card = styled.div`
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  grid-area: ${props => props.gridName && props.gridName};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;
export const ImageContainer = styled.div`
  img {
    height: 100px;
    width: 100px;
  }
  @media screen and (max-width: 885px) {
    img {
      height: 70px;
      width: 70px;
    }
  }
`;

export const DetailContainer = styled.div`
  font-size: 15px;
  font-weight: 600;
  padding: 5px 0;
  color: #333;
`;
