import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '../../services/api';
import SelectField from './selectField';

function SelectImeiField({ changeImeiValue, error, defaultValue, link }) {
  const [imeis, setImeis] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getImei() {
    try {
      const response = await api.fetchAvailableDevices();
      await setImeis(
        response.data
          .map(imei => ({
            label: imei.imei,
            value: imei.imei,
          }))
          .sort((a, b) => {
            if (a.label > b.label) {
              return 1;
            }
            if (a.label < b.label) {
              return -1;
            }
            return 0;
          })
      );
      await setLoading(false);
    } catch (err) {
      await setImeis([]);
      await setLoading(false);
    }
  }
  useEffect(() => {
    setLoading(true);
    getImei();
  }, []);

  return (
    <SelectField
      options={imeis || []}
      loading={loading}
      title="Imei"
      link={link}
      changeValueSelect={option => changeImeiValue(option)}
      placeholder="Selecione o imei"
      defaultValue={defaultValue}
      error={error}
    />
  );
}

SelectImeiField.propTypes = {
  changeImeiValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.bool,
  link: PropTypes.string,
};

SelectImeiField.defaultProps = {
  defaultValue: {},
  error: false,
  link: null,
};

export default SelectImeiField;
