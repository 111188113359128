import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Card from '../../components/cards/cardListType';
import { Creators as typeActions } from '../../store/ducks/typeSauce';

function Table({ history, height }) {
  const types = useSelector(state => state.typeState.types);
  const loading = useSelector(state => state.typeState.loading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(typeActions.fetchTypeRequest());
  }, [dispatch]);

  return (
    <div style={{ height, overflow: 'scroll' }}>
      <Card
        data={types}
        loading={loading}
        changeValue={data => history.push(`/type/form/${data.id}`)}
      />
    </div>
  );
}

Table.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  height: PropTypes.string,
};

Table.defaultProps = {
  height: '200px',
};

export default Table;
