import PropTypes from 'prop-types';
import React, { useRef, useState, useLayoutEffect } from 'react';

import Container from '../../components/containers/containerSimple';
import HeaderCard from '../../components/headerCard';
import Table from './table';

function Tracker({ history }) {
  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight - 75}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);
  return (
    <Container>
      <HeaderCard
        title="Item de rastreamento"
        route="/tracker/form"
        history={history}
      />
      <div style={{ height: '100%' }} ref={targetRef}>
        <Table history={history} height={height} />
      </div>
    </Container>
  );
}
Tracker.propTypes = {
  history: PropTypes.shape.isRequired,
};

export default Tracker;
