import styled, { css } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { darken, lighten } from 'polished';
import DatePicker from 'react-datepicker';
export const Container = styled.div`
  display: grid;
  grid-template-rows: 50px calc(100% - 80px) 30px;
  height: ${props => props.altura};
  width: 100%;
`;

export const Header = styled.header`
  ${props =>
    props.columns && !props.isGrouped
      ? css`
          display: grid;
          grid-template-columns: repeat(${props.columns}, 1fr);
        `
      : css`
          display: grid;
          grid-template-columns: 50px repeat(${props.columns}, 1fr);
        `}
  width: 100%;
  height: 50px;

  background: ${props => props.headerBackground};
  color: ${props => props.headerColor};
`;

export const HeaderItem = styled.div`
  ${props =>
    props.headerWidth &&
    css`
      width: ${props.headerWidth};
    `}
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    > span {
      padding-left: 5px;
      font-weight: 600;
    }
    display: flex;
    align-items: center;
    justify-content: ${props => props.align};
    cursor: pointer;
    > button {
      margin: -3px;
      position: absolute;
      right: 5px;
      border: none;
      background: ${props => darken(0.05, props.headerBackground)};
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  > input {
    height: 26px;
    margin-bottom: -6px;
    border: none;
    background: ${props => darken(0.05, props.headerBackground)};
    color: ${props => props.headerColor};
    transition: 0.2s;
    padding-left: 5px;
    ::placeholder {
      color: ${props => darken(0.1, props.headerColor)};
    }
    :hover {
      background: ${props => lighten(0.01, props.headerBackground)};
    }
  }
`;

export const Detail = styled(PerfectScrollbar)``;

export const DetailRow = styled.div`
  ${props =>
    props.columns && !props.isGrouped
      ? css`
          display: grid;
          grid-template-columns: repeat(${props.columns}, 1fr);
        `
      : css`
          display: grid;
          grid-template-columns: 50px repeat(${props.columns}, 1fr);
        `}
  height: 30px;
  cursor: ${props => (!props.cursor ? 'pointer' : 'default')};
  ${props =>
    props.detailWidth &&
    css`
      width: ${props.detailWidth};
    `}
  transition: 0.2s background;
  :nth-child(even) {
    background-color: ${props => props.stripedColor};
  }
  :hover {
    background: silver;
    color: black;
  }
`;

export const DetailItem = styled.div`
  ${props =>
    props.detailWidth &&
    css`
      width: ${props.detailWidth};
    `}
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: ${props => props.align};
`;

export const GroupItem = styled.div`
  width: 50px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.footerBackground};
  color: ${props => props.footerColor};

  > span {
    display: flex;
    flex-direction: column;
    > strong {
      font-size: 10px;
    }
    > small {
      font-size: 10px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    > button {
      cursor: pointer;
      margin: 0 5px;

      border: none;
      padding: 2px;
      background: rgba(0, 0, 0, 0.1);
      > img {
        height: 20px;
      }
    }
  }
`;

export const NumberContainer = styled.div`
  > input {
    height: 26px;
    width: 100%;
    margin-bottom: -6px;
    border: none;
    background: ${props => darken(0.05, props.headerBackground)};
    color: ${props => props.headerColor};
    transition: 0.2s;
    padding-left: 5px;
    ::placeholder {
      color: ${props => darken(0.1, props.headerColor)};
    }
    :hover {
      background: ${props => lighten(0.01, props.headerBackground)};
    }
  }
  > button {
    position: relative !important;
    width: 25px;
    height: 26px;
    background: ${props => darken(0.05, props.headerBackground)};
    color: ${props => props.headerColor};
    margin-bottom: -6px !important;

    :hover {
      background: ${props => lighten(0.01, props.headerBackground)};
    }
  }
`;

export const DateFilterStyled = styled(DatePicker)`
  height: 26px;
  border: none !important;
  width: 100%;
  margin-bottom: -6px;
  padding-left: 5px;
  font-family: 'Montserrat', sans-serif !important;
  color: #000;
  font-weight: 300 !important;
  background: ${props => darken(0.05, props.headerBackground)};
  color: ${props => props.headerColor};

  ::placeholder {
    color: ${props => darken(0.1, props.headerColor)};
  }
  :hover {
    background: ${props => lighten(0.01, props.headerBackground)};
  }
`;
