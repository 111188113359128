import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Client from '../pages/Client';
import ClientForm from '../pages/Client/form';
import Device from '../pages/Device';
import DeviceForm from '../pages/Device/form';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Message from '../pages/Message';
import Navbar from '../pages/Navbar';
import News from '../pages/News';
import NewsForm from '../pages/News/form';
import Permission from '../pages/Permission';
import PermissionLogbook from '../pages/PermissionLogbook';
import Report from '../pages/Report';
import Secretary from '../pages/Secretary';
import SecretaryForm from '../pages/Secretary/form';
import SideBar from '../pages/SideBar';
import Tracker from '../pages/Tracker';
import TrackerForm from '../pages/Tracker/form';
import TrackerByClient from '../pages/TrackerByClient';
import Type from '../pages/Type';
import TypeForm from '../pages/Type/form';
import User from '../pages/User';
import UserForm from '../pages/User/form';
import history from './history';
import PrivateRoute from './PrivateRoute';

function Routes({ token }) {
  return (
    <ConnectedRouter history={history}>
      <Navbar />
      <SideBar />
      <Switch>
        <Redirect exact from="/" to="home" />
        <Route path="/auth/login" component={Login} />
        <PrivateRoute path="/home" component={Home} token={token} />
        <PrivateRoute
          path="/client/form/:id"
          component={ClientForm}
          token={token}
        />
        <PrivateRoute
          path="/client/form"
          component={ClientForm}
          token={token}
        />
        <PrivateRoute path="/client" component={Client} token={token} />
        <PrivateRoute
          path="/device/form/:id"
          component={DeviceForm}
          token={token}
        />
        <PrivateRoute
          path="/device/form"
          component={DeviceForm}
          token={token}
        />
        <PrivateRoute path="/device" component={Device} token={token} />
        <PrivateRoute path="/message" component={Message} token={token} />
        <PrivateRoute path="/permission" component={Permission} token={token} />
        <PrivateRoute
          path="/logbook"
          component={PermissionLogbook}
          token={token}
        />
        <PrivateRoute
          path="/tracker/form/:id"
          component={TrackerForm}
          token={token}
        />
        <PrivateRoute
          path="/tracker/form"
          component={TrackerForm}
          token={token}
        />
        <PrivateRoute path="/tracker" component={Tracker} token={token} />
        <PrivateRoute
          path="/secretary/form/:id"
          component={SecretaryForm}
          token={token}
        />
        <PrivateRoute
          path="/secretary/form"
          component={SecretaryForm}
          token={token}
        />
        <PrivateRoute path="/secretary" component={Secretary} token={token} />
        <PrivateRoute
          path="/type/form/:id"
          component={TypeForm}
          token={token}
        />
        <PrivateRoute path="/type/form" component={TypeForm} token={token} />
        <PrivateRoute path="/type" component={Type} token={token} />
        <PrivateRoute
          path="/user/form/:id"
          component={UserForm}
          token={token}
        />
        <PrivateRoute path="/user/form" component={UserForm} token={token} />
        <PrivateRoute path="/user" component={User} token={token} />
        <PrivateRoute
          path="/news/form/:id"
          component={NewsForm}
          token={token}
        />
        <PrivateRoute path="/news/form" component={NewsForm} token={token} />
        <PrivateRoute path="/news" component={News} token={token} />
        <PrivateRoute
          path="/report/trackerByClient"
          component={TrackerByClient}
          token={token}
        />
        <PrivateRoute path="/report" component={Report} token={token} />
      </Switch>
    </ConnectedRouter>
  );
}

Routes.propTypes = {
  token: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  token: state.authState.token,
});

export default connect(mapStateToProps, null)(Routes);
