import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import LoadingDefault from '../../components/animations/loadingAnimationDefault';
import Box from '../../components/Box';
import SimpleButton from '../../components/buttons/simpleButton';
import ContainerForm from '../../components/containers/containerForm';
import SimpleField from '../../components/fields/simpleField';
import HeaderCardForm from '../../components/headerCardForm';
import {
  CheckBoxInput,
  SimpleInput,
  MaskedInput,
} from '../../components/inputs';
import TableComponent from '../../components/Table';
import { Creators as clientActions } from '../../store/ducks/clientSauce';
// import { Container } from './styles';
function Client({ match, history }) {
  const userLoggedId = useSelector(state => state.authState.user.id);
  const client = useSelector(state => state.clientState.client);
  const loading = useSelector(state => state.clientState.loading);
  const [isCpf, setIsCpf] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      document: '',
      commentary: '',
      user_id: '',
      status: false,
      defaulter: false,
      phone_emergency: '',
      contact_emergency: '',
      email_emergency: '',
      monitoring_center: false,
    },
    validate: values => {
      const err = {};
      const message = 'Campo obrigatório!';
      if (!values.name) err.name = message;
      if (!values.phone) err.phone = message;
      if (!values.document) err.document = message;
      if (values.commentary && values.commentary.length > 255) {
        err.commentary = 'Limite de caracteres atingido!';
      }

      return err;
    },
    onSubmit: data => {
      if (data.id) {
        if (!userLoggedId) {
          toast.warn('ID do Usuário não encontrado,realize o login novamente!');
          return;
        }
        data.user_id = userLoggedId;
        dispatch(clientActions.updateClientRequest(data));
      } else {
        dispatch(clientActions.createClientRequest(data));
      }
    },
  });

  function showBlocksHistory() {
    setShowHistory(!showHistory);
  }

  useEffect(() => {
    if (match.params.id) {
      dispatch(clientActions.fetchClientRequest(`/${match.params.id}`));
    } else {
      formik.setValues({ ...formik.values, role: 'basic' });
    }
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (!_.isEmpty(client)) {
      setIsCpf(client.document.length <= 14);
      formik.setValues({
        ...client,
        blocks: client.blocks?.map(item => ({
          ...item,
          block: new Date(item.block).toLocaleString(),
          unlock: item.unlock ? new Date(item.unlock).toLocaleString() : '-',
        })),
        commentary:
          client.blocks.length &&
          client.blocks.find(({ block, unlock }) => block && !unlock)
            ? client.blocks[0].commentary
            : '',
      });
    }
  }, [client]);

  useEffect(() => {
    formik.setValues({ ...formik.initialValues, status: true });
  }, []);

  return (
    <ContainerForm onSubmit={formik.handleSubmit}>
      <HeaderCardForm
        title="Formulário de cliente"
        route="/client"
        history={history}
        loading={loading}
      />
      {loading ? (
        <LoadingDefault />
      ) : (
        <Box>
          <div className="pretty p-icon p-round p-smooth">
            <div className="state p-info">
              <MdCheck className="icon" />
              <label htmlFor="documentation">
                <input
                  type="radio"
                  id="documentation"
                  name="documentation"
                  checked={!isCpf}
                  onClick={() => setIsCpf(!isCpf)}
                />
                Pessoa Jurídica
              </label>
            </div>
          </div>
          <SimpleField>
            <div>Nome</div>
            <SimpleInput
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
            />
          </SimpleField>
          <SimpleField>
            <div>Telefone</div>
            <MaskedInput
              mask="(99)9 9999-9999"
              name="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
              error={formik.touched.phone && formik.errors.phone}
            />
          </SimpleField>
          {/* <SimpleField>
            <div>Contato de emergência</div>
            <SimpleInput
              name="contact_emergency"
              onChange={formik.handleChange}
              value={formik.values.contact_emergency}
            />
          </SimpleField> */}
          <SimpleField>
            <div>E-mail de emergência</div>
            <SimpleInput
              name="email_emergency"
              onChange={formik.handleChange}
              value={formik.values.email_emergency}
            />
          </SimpleField>
          <SimpleField>
            <div>Telefone de emergência</div>
            <MaskedInput
              mask="(99)9 9999-9999"
              name="phone_emergency"
              onChange={formik.handleChange}
              value={formik.values.phone_emergency}
            />
          </SimpleField>

          <SimpleField>
            <div>CPF/CNPJ</div>
            <MaskedInput
              mask={isCpf ? '999.999.999-99' : '99.999.999/9999-99'}
              name="document"
              onChange={formik.handleChange}
              value={formik.values.document}
              error={formik.touched.document && formik.errors.document}
            />
          </SimpleField>
          <SimpleField>
            <div>Comentário (referente à inadimplência do cliente)</div>
            <SimpleInput
              name="commentary"
              onChange={formik.handleChange}
              value={formik.values.commentary}
              error={formik.touched.commentary && formik.errors.commentary}
            />
          </SimpleField>
          <CheckBoxInput
            label="Ativo"
            changeValue={value => formik.setFieldValue('status', value)}
            isChecked={formik.values.status}
          />
          <CheckBoxInput
            label="Inadimplente"
            changeValue={value => formik.setFieldValue('defaulter', value)}
            isChecked={formik.values.defaulter}
          />
          <CheckBoxInput
            onClick={() =>
              formik.setFieldValue(
                'monitoring_center',
                !formik.values.monitoring_center
              )
            }
            label="Central"
            changeValue={value =>
              formik.setFieldValue('monitoring_center', value)
            }
            isChecked={formik.values.monitoring_center}
          />
          <SimpleButton
            width="230px"
            font="14px"
            height="40px"
            bg="#245278"
            color="#FFF"
            text={
              showHistory
                ? 'Ocultar Histórico de Bloqueios '
                : 'Mostrar Histórico de Bloqueios'
            }
            onClick={() => showBlocksHistory()}
          />
          {showHistory ? (
            <TableComponent
              data={formik.values.blocks}
              headerBackground="#245378"
              fileTitle="Historico de Bloqueios"
              name="cliente"
              headers={['block', 'unlock', 'commentary']}
              labels={['Data de Bloqueio', 'Data do Desbloqueio', 'Comentário']}
              getData={() => {}}
            />
          ) : (
            ''
          )}
        </Box>
      )}
    </ContainerForm>
  );
}

Client.propTypes = {
  history: PropTypes.objectOf.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Client;
