import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setTrackerMessage: ['data'],
  setEnableModal: ['modal'],
});

const INITIAL_STATE = {
  tracker: {},
  modal: false,
};

const setTrackerMessage = (state = INITIAL_STATE, { data }) => ({
  ...state,
  tracker: data,
});

const setEnableModal = (state = INITIAL_STATE, { modal }) => ({
  ...state,
  modal,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_TRACKER_MESSAGE]: setTrackerMessage,
  [Types.SET_ENABLE_MODAL]: setEnableModal,
});
