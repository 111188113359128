import React from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';

import SimpleButton from '../../components/buttons/simpleButton';
import { Creators as messageActions } from '../../store/ducks/messageSauce';
import { ModalContainer, Title, Descripition } from './styles';

export default function OutputModal() {
  const dispatch = useDispatch();
  const visible = useSelector(state => state.messageState.modal === 'output');
  const device = useSelector(state => state.deviceState.device);

  function sendMessage(type) {
    let msg = '';
    if (device.model === 'SUNTECH' && device.type === 'ST4315U') {
      let portEnableDisable = ''; // (type === 'Enable1' ? '01' : (type === 'Disable1' ? '02' : (type === 'Enable2' ? '03' : (type === 'Disable2' ? '04' : ''))));
      switch (type) {
        case 'Enable1':
          portEnableDisable = '01';
          break;
        case 'Disable1':
          portEnableDisable = '02';
          break;
        case 'Enable2':
          portEnableDisable = '03';
          break;
        case 'Disable2':
          portEnableDisable = '04';
          break;
        default:
          return '';
      }
      msg = `sms://${device.phone}?body=CMD;${device.imei};04;${portEnableDisable}`;
    } else if (device.model === 'SUNTECH') {
      msg = `sms://${device.phone}?body=ST300CMD;${device.imei};02;${type}`;
    } else if (device.model === 'VITANA') {
      const output = type === 'Enable1' || type === 'Disable1' ? 1 : 2;
      const enable = type === 'Enable1' || type === 'Enable2' ? '1' : '0';
      const speed = enable === '1' ? ',0,1' : '';
      msg = `sms://${device.phone}?body=0000,900,${output},${enable}${speed}`;
    }
    window.location.href = msg;
    return '';
  }
  return (
    <Modal
      visible={visible}
      onClickAway={() => dispatch(messageActions.setEnableModal(false))}
      height="320"
      width="300"
      effect="fadeInUp"
    >
      <ModalContainer>
        <Title>Saídas</Title>
        <Descripition>
          <SimpleButton
            text="Habilitar saída 1"
            bg="#01659e"
            color="#eee"
            onClick={() => sendMessage('Enable1')}
          />
          <SimpleButton
            text="Desabilitar saída 1"
            bg="#ff4e4e"
            color="#eee"
            onClick={() => sendMessage('Disable1')}
          />
          <SimpleButton
            text="Habilitar saída 2"
            bg="#01659e"
            color="#eee"
            onClick={() => sendMessage('Enable2')}
          />
          <SimpleButton
            text="Desabilitar saída 2"
            bg="#ff4e4e"
            color="#eee"
            onClick={() => sendMessage('Disable2')}
          />
        </Descripition>
        {/* <ButtonsContainer>
          <SimpleButton
            text="Cancelar"
            bg="#ff4e4e"
            color="#eee"
            onClick={() => dispatch(messageActions.setEnableModal(false))}
          />
          <SimpleButton
            type="subbmit"
            text="Enviar"
            bg="#01659e"
            color="#eee"
          />
        </ButtonsContainer> */}
      </ModalContainer>
    </Modal>
  );
}
