import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as SecretaryActions } from '../../store/ducks/secretarySauce';
import SelectField from './selectField';

function SelectSecretaryField({clientId, changeSecretaryValue, error, defaultValue }) {
  const loading = useSelector(state => state.secretaryState.loading);
  const clienId = clientId;
  const dispatch = useDispatch();
  const secretaries = useSelector(state =>
    state.secretaryState.secretaries
      .map(secretary => ({
        label: secretary.name,
        value: secretary.id,
      }))
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      })
  );

  useEffect(() => {
    if (clienId !== null)
      dispatch(
        SecretaryActions.fetchSecretaryRequest(`?id_clients=[${clienId}]`)
      );
  }, [clienId]);

  return (
    <SelectField
      options={secretaries || []}
      loading={loading}
      defaultValue={defaultValue}
      title="Secretaria"
      changeValueSelect={option => changeSecretaryValue(option)}
      placeholder="Selecione a secretaria"
      error={error}
    />
  );
}

SelectSecretaryField.propTypes = {
  changeSecretaryValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.bool,
};

SelectSecretaryField.defaultProps = {
  defaultValue: {},
  error: false,
};

export default SelectSecretaryField;
