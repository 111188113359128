import { useFormik } from 'formik';
import React from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';

import SimpleButton from '../../components/buttons/simpleButton';
import MaskedInput from '../../components/inputs/maskedInput';
import { Creators as messageActions } from '../../store/ducks/messageSauce';
import {
  ModalContainer,
  Title,
  Descripition,
  ButtonsContainer,
} from './styles';

export default function TimerModal() {
  const dispatch = useDispatch();
  const visible = useSelector(state => state.messageState.modal === 'timer');
  const device = useSelector(state => state.deviceState.device);
  const formik = useFormik({
    initialValues: {
      stoped: '480',
      running: '30',
      angle: '60',
    },
    validate: value => {
      const err = {};
      if (!value.stoped) err.stoped = 'Campo obrigatório!';
      if (!value.running) err.running = 'Campo obrigatório!';
      if (!value.angle) err.angle = 'Campo obrigatório!';
      return err;
    },
    onSubmit: data => {
      if (device.model === 'SUNTECH' && device.type === 'ST4315U') {
        const {
          angle,
          stoped: ignitionOff,
          running: ignitionOn,
          running: stopIgnitionOn,
        } = data;
        window.location.href = `sms://${device.phone}?body=PRG;${device.imei};16;70#${ignitionOff};71#0;72#0;73#${stopIgnitionOn};74#0;75#${angle};76#0;77#0;78#0;79#${ignitionOn};80#0;81#${angle};82#60;83#0;84#0;85#60;86#0;87#0`;
      } else if (device.model === 'SUNTECH') {
        window.location.href = `sms://${device.phone}?body=ST300RPT;${device.imei};02;${data.stoped};${data.running};${data.running};1;0;0;0;0;0`;
      } else if (device.model === 'QUECLINK') {
        window.location.href = `sms://${device.phone}?body=AT+GTFRI=gv75,1,1,,1,0000,0000,,${data.running},1000,1000,,0,${data.stoped},0,,,,FFFF$`;
      } else if (device.model === 'VITANA') {
        window.location.href = `sms://${device.phone}?body=0000,102,${data.running},${data.stoped},${data.running}`;
      }
    },
  });
  return (
    <Modal
      visible={visible}
      onClickAway={() => dispatch(messageActions.setEnableModal(false))}
      height="300"
      width="300"
      effect="fadeInUp"
    >
      <ModalContainer onSubmit={formik.handleSubmit}>
        <Title>Tempo de transmissão</Title>
        <Descripition>
          <MaskedInput
            placeholder="Digite o tempo Parado"
            mask="9999"
            name="stoped"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.stoped}
            error={!!(formik.touched.stoped && formik.errors.stoped)}
          />
          <MaskedInput
            placeholder="Digite o tempo em movimento"
            mask="9999"
            name="running"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.running}
            error={!!(formik.touched.running && formik.errors.running)}
          />
          <MaskedInput
            placeholder="Digite o ângulo"
            mask="99"
            name="angle"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.angle}
            error={!!(formik.touched.angle && formik.errors.angle)}
          />
        </Descripition>
        <ButtonsContainer>
          <SimpleButton
            text="Cancelar"
            bg="#ff4e4e"
            color="#eee"
            onClick={() => dispatch(messageActions.setEnableModal(false))}
          />
          <SimpleButton
            type="subbmit"
            text="Enviar"
            bg="#01659e"
            color="#eee"
          />
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  );
}
