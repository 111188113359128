import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import Box from '../../components/BoxFilter';
import Card from '../../components/cards/cardListTracker';
import TableComponent from '../../components/Table';
import { Creators as SecretaryActions } from '../../store/ducks/secretarySauce';
import { ContainerTable } from './styles';

function Table({ history, height }) {
  const secretaries = useSelector(state => state.secretaryState.secretaries);
  const loading = useSelector(state => state.secretaryState.loading);
  const clientId = localStorage.getItem('clientId')
  const dispatch = useDispatch();

  useEffect(() => {
    if (clientId) {
      dispatch(SecretaryActions.fetchSecretaryRequest(`?id_clients=[${clientId}]`));
    }
  }, [dispatch, clientId])

  const formik = useFormik({
    initialValues: {
      name: '',
      acronym:  '',
    },
    onSubmit: data => {
      const { name, acronym } = data;
      dispatch(SecretaryActions.fetchFilter(name.toUpperCase(), acronym.toUpperCase()));
    },
  });
  const [widthPage, setWidth] = useState(window.innerWidth);

  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <ContainerTable>
        {widthPage <= 700 &&
      <Box columns={2}>
        <form onSubmit={formik.handleSubmit}>
          <button type="subbmit">
            <MdSearch size={20} color="#027ab7" />
          </button>
        </form>
      </Box>
      }
      <div style={{ height }}>
        {widthPage <= 700 ? (
          <Card
            data={secretaries}
            loading={loading}
            changeValue={data => history.push(`/secretary/form/${data.id}`)}
          />
        ) : (
          <Box>
            <TableComponent
              data={secretaries.map(item => ({
                ...item,
                // client_id: `${clientsName.find((item) => item.id === item.client_id).name}`
              }))}
              fileTitle="Relatorio de secretarias"
              name="Secretarias"
              headers={['name', 'acronym']}
              labels={['Nome', 'Sigla']}
              getData={data => history.push(`/secretary/form/${data.id}`)}
            />
          </Box>
        )}
      </div>
    </ContainerTable>
  );
}

Table.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  height: PropTypes.string,
};

Table.defaultProps = {
  height: '200px',
};

export default Table;
