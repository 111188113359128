import styled from 'styled-components';

export const Container = styled.div`
  grid-area: side;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 700px) {
    flex-direction: row;
  }
`;

export const CarItem = styled.div`
  padding: 5px;
  transition: 0.2s;
  display: flex;
  // margin-left: 27px;
  justify-content: center;
  border-radius: 0 10px 10px 0;
  @media screen and (max-width: 700px) {
    border-radius: 10px 10px 0 0;
  }
  background: ${props => props.selected && 'rgba(0,0,0,0.15)'};
  height: ${props => (props.selected ? '50px' : '40px')};
  width: ${props => (props.selected ? '50px' : '40px')};
  img {
    height: ${props => (props.selected ? '40px' : '30px')};
    width: ${props => (props.selected ? '40px' : '30px')};
    // margin-left:  ${props => (props.selected ? '27px' : '30px')};
    :hover {
      height: 40px;
      width: 40px;
    }
  }
  :hover {
    height: 50px;
    width: 50px;
    background: rgba(0, 0, 0, 0.1);
  }
`;
