import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '../../services/api';
import { Types } from '../ducks/clientSauce';

export function* requestClientSaga(payload) {
  try {
    const response = yield call(api.fetchClient, payload.data);
    if (_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_CLIENT_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_CLIENT_ID_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Types.FETCH_CLIENT_FAILURE });
  }
}

export function* updateClientSaga(payload) {
  try {
    yield call(api.updateClient, payload.data);
    yield put({
      type: Types.FETCH_CLIENT_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/client'));
  } catch (error) {
    yield put({ type: Types.FETCH_CLIENT_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export function* createClientSaga(payload) {
  try {
    yield call(api.createClient, payload.data);
    yield put({
      type: Types.FETCH_CLIENT_REQUEST,
    });
    toast.success('Cliente cadastrado com sucesso!');
    yield put(push('/client'));
  } catch (error) {
    yield put({ type: Types.FETCH_CLIENT_FAILURE });
    toast.warning('Erro ao cadastrar o clientes');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_CLIENT_REQUEST, requestClientSaga)]);
  yield all([takeEvery(Types.UPDATE_CLIENT_REQUEST, updateClientSaga)]);
  yield all([takeEvery(Types.CREATE_CLIENT_REQUEST, createClientSaga)]);
}
