import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';

import animationData from '../../assets/lottie/loadingFloatButton.json';

const Div = styled.div`
  align-self: center;
`;

export default function LottieLoading() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return (
    <Div>
      <Lottie
        options={defaultOptions}
        height={45}
        width="100%"
        isPaused={false}
      />
    </Div>
  );
}
