import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchSecretaryRequest: ['data'],
  fetchSecretarySuccess: ['data'],
  fetchSecretaryIdSuccess: ['data'],
  fetchSecretaryByClientSuccess: ['data'],
  fetchSecretaryByClientRequest: ['data'],
  fetchSecretaryFailure: [],
  updateSecretaryRequest: ['data'],
  updateSecretarySuccess: [],
  createSecretaryRequest: ['data'],
});

const INITIAL_STATE = {
  secretaries: [],
  secretariesImutable: [],
  secretary: {},
  secretaryClient: [],
  loading: false,
};

const fetchSecretaryRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchSecretaryByClientRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchSecretarySuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  secretaries: data,
  secretariesImutable: data,
  loading: false,
});

const fetchSecretaryIdSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  secretary: actions.data,
  loading: false,
});

const fetchSecretaryByClientSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    secretaryClient: actions.data,
    loading: false,
  };
};

const fetchSecretaryFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    secretaries: [],
  };
};

const createSecretaryRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateSecretaryRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
const updateSecretarySuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_SECRETARY_REQUEST]: fetchSecretaryRequest,
  [Types.FETCH_SECRETARY_SUCCESS]: fetchSecretarySuccess,
  [Types.FETCH_SECRETARY_FAILURE]: fetchSecretaryFailure,
  [Types.FETCH_SECRETARY_ID_SUCCESS]: fetchSecretaryIdSuccess,
  [Types.FETCH_SECRETARY_BY_CLIENT_SUCCESS]: fetchSecretaryByClientSuccess,
  [Types.FETCH_SECRETARY_BY_CLIENT_REQUEST]: fetchSecretaryByClientRequest,
  [Types.UPDATE_SECRETARY_REQUEST]: updateSecretaryRequest,
  [Types.UPDATE_SECRETARY_SUCCESS]: updateSecretarySuccess,
  [Types.CREATE_SECRETARY_REQUEST]: createSecretaryRequest,
});
