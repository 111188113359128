import React, { useRef, useState, useLayoutEffect } from 'react';

import Container from '../../components/containers/containerSimple';
import HeaderCard from '../../components/headerCard';
import Table from './table';
import history from '../../routes/history';

function News() {
  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight - 75}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <Container>
      <HeaderCard
        title="Novidades do sistema"
        route="/news/form"
        history={history}
      />
      <div style={{ height: '100%' }} ref={targetRef}>
        <Table history={history} height={'600px'} />
      </div>
    </Container>
  );
}
export default News;
