import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import Box from '../../components/BoxFilter';
import Card from '../../components/cards/cardListUser';
import SimpleInput from '../../components/inputs/simpleInput';
import TableComponent from '../../components/Table';
import { Creators as userActions } from '../../store/ducks/userSauce';
import { ContainerTable } from './styles';

function User({ history, height }) {
  const users = useSelector(state => state.userState.users);
  const loading = useSelector(state => state.userState.loading);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: data => {
      const { name } = data;

      dispatch(userActions.fetchFilters(name));
    },
  });
  useEffect(() => {
    dispatch(userActions.fetchUserRequest());
  }, [dispatch]);

  const [widthPage, setWidth] = useState(window.innerWidth);

  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <ContainerTable>
      {widthPage <= 700 &&
      <Box columns={1}>
        <form onSubmit={formik.handleSubmit}>
          <SimpleInput
            placeholder="filtrar por nome"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <button type="subbmit">
            <MdSearch size={20} color="#027ab7" />
          </button>
        </form>
      </Box>
      }
      <div style={{ height, overflow: 'scroll' }}>
        {widthPage <= 700 ? (
          <Card
            data={users}
            loading={loading}
            changeValue={data => history.push(`/user/form/${data.id}`)}
          />
        ) : (
          <Box>
            <TableComponent
              data={users.map(item => ({
                ...item,
                active: item.active ? 'Ativo' : 'Inativo',
              }))}
              fileTitle="Relatório de usuários"
              name="usuários"
              labels={['Nome', 'E-mail', 'CPF', 'Telefone']}
              headers={['name', 'email', 'cpf', 'phone']}
              getData={data => history.push(`/user/form/${data.id}`)}
            />
          </Box>
        )}
      </div>
    </ContainerTable>
  );
}

User.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  height: PropTypes.string,
};

User.defaultProps = {
  height: '200px',
};

export default User;