import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '../../services/api';
import { Types } from '../ducks/trackerSauce';

export function* requestTrackerSaga(payload) {
  try {
    const response = yield call(api.fetchTracker, payload.data);
    if (_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_TRACKER_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_TRACKER_ID_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Types.FETCH_TRACKER_FAILURE });
  }
}

export function* requestTrackerByClientSaga(payload) {
  try {
    const response = yield call(
      api.fetchTrackerByClient,
      `?id_clients=${JSON.stringify(payload.data)}`
    );
    yield put({
      type: Types.FETCH_TRACKER_BY_CLIENT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_TRACKER_FAILURE });
  }
}

export function* updateTrackerSaga(payload) {
  try {
    yield call(api.updateTracker, payload.data);
    toast.success('Atualizado com sucesso!');
    yield put(push('/tracker'));
  } catch (error) {
    yield put({ type: Types.FETCH_TRACKER_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export function* createTrackerSaga(payload) {
  try {
    yield call(api.createTracker, payload.data);
    toast.success('Veículo cadastrado com sucesso!');
    yield put(push('/tracker'));
  } catch (error) {
    yield put({ type: Types.FETCH_TRACKER_FAILURE });
    toast.warning('Erro ao cadastrar o veículo');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_TRACKER_REQUEST, requestTrackerSaga)]);
  yield all([
    takeEvery(
      Types.FETCH_TRACKER_BY_CLIENT_REQUEST,
      requestTrackerByClientSaga
    ),
  ]);
  yield all([takeEvery(Types.UPDATE_TRACKER_REQUEST, updateTrackerSaga)]);
  yield all([takeEvery(Types.CREATE_TRACKER_REQUEST, createTrackerSaga)]);
}
