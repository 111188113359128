import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '../../services/api';
import { Types } from '../ducks/deviceSauce';

export function* requestDeviceSaga(payload) {
  try {
    const response = yield call(api.fetchDevice, payload.data);
    if (_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_DEVICE_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_DEVICE_ID_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Types.FETCH_DEVICE_FAILURE });
  }
}

export function* updateDeviceSaga(payload) {
  try {
    yield call(api.updateDevice, payload.data);
    yield put({
      type: Types.FETCH_DEVICE_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/device'));
  } catch (error) {
    yield put({ type: Types.FETCH_DEVICE_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export function* createDeviceSaga(payload) {
  try {
    yield call(api.createDevice, payload.data);
    yield put({
      type: Types.FETCH_Device_REQUEST,
    });
    toast.success('Devicee cadastrado com sucesso!');
    yield put(push('/Device'));
  } catch (error) {
    yield put({ type: Types.FETCH_Device_FAILURE });
    toast.warning('Erro ao cadastrar o Devicee');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_DEVICE_REQUEST, requestDeviceSaga)]);
  yield all([takeEvery(Types.UPDATE_DEVICE_REQUEST, updateDeviceSaga)]);
  yield all([takeEvery(Types.CREATE_DEVICE_REQUEST, createDeviceSaga)]);
}
