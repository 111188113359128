import styled, { css } from 'styled-components';

export const Header = styled.div`
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  > div {
    font-size: 20px;
    font-weight: 600;
  }
  @media screen and (max-width: 700px) {
    ${props =>
      props.permission &&
      css`
        flex-direction: column;
        align-items: self-start;
        padding: 0px 10px;
        justify-content: space-evenly;
      `}
  }
`;

export const ContainerUser = styled.div`
  width: 100%;
  margin: 5px 0;
`;

export const ContainerMessage = styled.div`
  display: grid;
  grid-template-columns: 31% 31% 31%;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  justify-content: center;
`;

export const CardMessage = styled.a`
  background: #dbdbdb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.disabled ? '0.1' : '1')};
  padding: 10px;
  > img {
    height: 80px;
  }
`;

export const ModalContainer = styled.form`
  height: 100%;
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  padding: 10px;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  color: #01659e;
`;

export const Descripition = styled.div`
  ${props =>
    props.output &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 50px 5px;
    `}
`;
