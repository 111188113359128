import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import brands from '../../assets/json/brands.json';
import Animation from '../animations/loadingAnimationDefault';
import SelectField from './selectField';

function SelectBrandField({ changeBrandValue, error, defaultValue }) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const formatedBrand = brands
      .map(brand => ({ label: brand.fipe_name, value: brand.fipe_name }))
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
    setOptions(formatedBrand);
  }, []);

  return (
    <>
      {_.isEmpty(options) ? (
        <Animation />
      ) : (
        <SelectField
          options={options}
          title="Marca"
          changeValueSelect={option => changeBrandValue(option)}
          placeholder="Selecione a marca"
          defaultValue={defaultValue}
          error={error}
        />
      )}
    </>
  );
}

SelectBrandField.propTypes = {
  changeBrandValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.bool,
};

SelectBrandField.defaultProps = {
  defaultValue: {},
  error: false,
};

export default SelectBrandField;
