import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchTypeRequest: ['data'],
  fetchTypeSuccess: ['data'],
  fetchTypeIdSuccess: ['data'],
  fetchTypeFailure: [],
  updateTypeRequest: ['data', 'id'],
  createTypeRequest: ['data'],
});

const INITIAL_STATE = {
  types: [],
  type: {},
  loading: false,
};

const fetchTypeRequest = (state = INITIAL_STATE) => ({
  ...state,
  types: [],
  type: {},
  loading: true,
});

const fetchTypeSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  types: data,
  loading: false,
});

const fetchTypeIdSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  type: actions.data,
  loading: false,
});

const fetchTypeFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    types: [],
  };
};

const updateTypeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const createTypeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_TYPE_REQUEST]: fetchTypeRequest,
  [Types.FETCH_TYPE_SUCCESS]: fetchTypeSuccess,
  [Types.FETCH_TYPE_ID_SUCCESS]: fetchTypeIdSuccess,
  [Types.FETCH_TYPE_FAILURE]: fetchTypeFailure,
  [Types.UPDATE_TYPE_REQUEST]: updateTypeRequest,
  [Types.CREATE_TYPE_REQUEST]: createTypeRequest,
});
