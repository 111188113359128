import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    border: none;
    background: rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 100px;
    height: 100px;
    border-radius: 10px;
    > img {
      width: 50px;
      height: 50px;
    }
    > strong {
      font-size: 9px;
    }
  }
`;
