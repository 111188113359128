import React, { useRef, useState, useLayoutEffect } from 'react';

import Box from '../../components/Box';
import Container from '../../components/containers/containerSimple';
import MessageSide from './messageSide';
import OdometerModal from './odometerModal';
import OrimeterModal from './orimeterModal';
import OutputModal from './outputModal';
import ServerModal from './serverModal';
import SleepModal from './sleepModal';
import { Header } from './styles';
import TimerModal from './timerModal';
import TrackerSide from './trackerSide';

export default function Message() {
  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);
  return (
    <>
      <Container>
        <Header>
          <div>Mensagens</div>
        </Header>
        <Box permission>
          <div style={{ height: '100%' }} ref={targetRef}>
            <TrackerSide setHeight={height} />
          </div>
          <MessageSide />
        </Box>
      </Container>
      <OdometerModal />
      <TimerModal />
      <ServerModal />
      <OutputModal />
      <OrimeterModal />
      <SleepModal />
    </>
  );
}
