import { ExportToCsv } from 'export-to-csv';

export default function exportCsv(data, headers, fileTitle) {
  const options = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: fileTitle,
    filename: fileTitle,
    useTextFile: false,
    useBom: true,
    headers,
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}
