import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import AnimationLoading from '../buttons/animationLoading';
import SimpleSelect from '../selects/simpleSelect';

const Container = styled.div`
  padding: 10px;
  > span {
    font-size: 16px;
    font-weight: 600;
  }
`;

const AnimationContainer = styled.div`
  width: 100%;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.001);
  border: 1px solid #dbdbdb;
  border-radius: 500px;
  height: 35px;
`;

function SelectField({
  title,
  placeholder,
  changeValueSelect,
  options,
  loading,
  disabled,
  defaultValue,
  error,
  link,
}) {
  const [copyValue, setCopyValue] = useState('');
  useEffect(() => {
    if (defaultValue && defaultValue.value)
      setCopyValue(defaultValue.value.toString());
  }, [defaultValue]);
  return (
    <Container>
      <span>{title}</span>
      {link && <a href={link}> editar </a>}
      {copyValue && (
        <a
          href="#"
          onClick={() => navigator.clipboard.writeText(copyValue)}
          title="clique para copiar o valor"
        >
          {' '}
          copiar{' '}
        </a>
      )}
      {loading ? (
        <AnimationContainer>
          <AnimationLoading />
        </AnimationContainer>
      ) : (
        <SimpleSelect
          isClearable
          onChange={option => changeValueSelect(option)}
          placeholder={placeholder}
          options={options || []}
          isDisabled={disabled}
          noOptionsMessage={() => 'Nenhum registro encontrado!'}
          defaultValue={defaultValue}
          error={error}
        />
      )}
    </Container>
  );
}

SelectField.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  changeValueSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.bool,
  link: PropTypes.string,
};

SelectField.defaultProps = {
  loading: false,
  disabled: false,
  options: [],
  placeholder: 'Selecione um item',
  defaultValue: {},
  error: false,
  link: null,
};

export default SelectField;
