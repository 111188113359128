import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SimpleButton from '../../components/buttons/simpleButton';
import SimpleField from '../../components/fields/simpleField';
import MaskedInput from '../../components/inputs/maskedInput';
import PasswordInput from '../../components/inputs/passwordInput';
import { Creators as AuthActions } from '../../store/ducks/authSauce';
import AnimationWorld from './animationWorld';
import { Container, TitleContainer } from './styles';

function Login({ history }) {
  const token = useSelector(state => state.authState.token);
  const loading = useSelector(state => state.authState.loading);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      cpf: '',
      password: '',
    },
    validate: values => {
      const error = {};
      const message = 'Campo obrigatório';
      if (_.isEmpty(values.cpf)) error.cpf = message;
      if (_.isEmpty(values.password)) error.password = message;
      return error;
    },
    onSubmit: data => {
      dispatch(AuthActions.loginRequest(data));
    },
  });
  useEffect(() => {
    if (token) history.push('/home');
  }, [token, history]);

  const [widthPage, setWidth] = useState(window.innerWidth);

  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <Container>
      <div>
        <TitleContainer>GEORASTREAMENTO</TitleContainer>
        <AnimationWorld />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <SimpleField
          color="#fff"
          error={formik.touched.cpf && formik.errors.cpf}
        >
          <div>CPF</div>
          <MaskedInput
            mask="999.999.999-99"
            background="rgba(2,122,183, 0.05)"
            color="#fff"
            name="cpf"
            onChange={formik.handleChange}
            value={formik.values.cpf}
            error={formik.touched.cpf && formik.errors.cpf}
          />
        </SimpleField>
        <SimpleField
          color="#fff"
          error={formik.touched.password && formik.errors.password}
        >
          <div>SENHA</div>
          <PasswordInput
            background="rgba(2,122,183, 0.05)"
            color="#fff"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
          />
        </SimpleField>
        <SimpleButton
          text="ENTRAR"
          bg={() => (widthPage <= 700 ? '#027ab7' : '#3e3838')}
          color="#fff"
          type="subbmit"
          loading={loading}
        />
      </form>
    </Container>
  );
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Login;
