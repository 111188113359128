import PropTypes from 'prop-types';
import React from 'react';
import { MdAdd } from 'react-icons/md';

import IconButton from '../buttons/iconButton';
import { Container } from './styles';

function headerCard({ title, route, history }) {
  return (
    <Container>
      <div>{title}</div>
      <IconButton
        text="Criar"
        bg="#027ab7"
        color="#fff"
        type="button"
        Icon={MdAdd}
        onClick={() => history.push(route)}
      />
    </Container>
  );
}

headerCard.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default headerCard;
