import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import Box from '../../components/BoxFilter';
import SimpleButton from '../../components/buttons/simpleButton';
import Card from '../../components/cards/cardListClient';
import SimpleInput from '../../components/inputs/simpleInput';
import TableComponent from '../../components/Table';
import { Creators as clientActions } from '../../store/ducks/clientSauce';
import { ContainerTable } from './styles';

function User({ history, height }) {
  const [filterClients, filterClientsDefaulter] = useState(false);
  const [clientsFormatted, setClients] = useState([]);
  const clients = useSelector(state => state.clientState.clients);
  const loading = useSelector(state => state.clientState.loading);
  const dispatch = useDispatch();

  const headers = ['name', 'document', 'formatedAddress', 'phone'];
  const labels = ['Nome', 'CPF/CNPJ', 'Endereço', 'Telefone'];

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: data => {
      const { name } = data;
      dispatch(clientActions.fetchFilters(name));
    },
  });

  function formatClients(item) {
    return {
      ...item,
      blockDate:
        item.blocks.length &&
        item.blocks.find(({ block, unlock }) => block && !unlock)
          ? new Date(item.blocks[0]?.block).toLocaleString()
          : '-',
      defaulter: item.defaulter ? 'Inadimplente' : '-',
      formatedAddress: `${item.address || 'N/D'} - ${item.city}/ ${item.state}`,
    };
  }

  useEffect(() => {
    dispatch(clientActions.fetchClientRequest());
    setClients(clients.map(formatClients));
  }, [dispatch]);

  useEffect(() => {
    setClients(clients.map(formatClients));
  }, [clients]);

  useEffect(() => {
    if (filterClients) {
      setClients(clients.filter(i => i.defaulter).map(formatClients));
    } else {
      setClients(clients.map(formatClients));
    }
  }, [filterClients]);

  const [widthPage, setWidth] = useState(window.innerWidth);

  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <ContainerTable>
      {widthPage <= 700 && (
        <Box columns={1}>
          <form onSubmit={formik.handleSubmit}>
            <SimpleInput
              placeholder="filtrar por nome"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <button type="submit">
              <MdSearch size={20} color="#027ab7" />
            </button>
          </form>
        </Box>
      )}
      <div style={{ height, overflow: 'scroll' }}>
        {widthPage <= 700 ? (
          <Card
            data={clientsFormatted}
            loading={loading}
            changeValue={data => history.push(`/client/form/${data.id}`)}
          />
        ) : (
          <Box>
            <SimpleButton
              width="230px"
              font="14px"
              height="40px"
              bg="#245278"
              color="#FFF"
              text={
                filterClients
                  ? 'Remover Filtro'
                  : 'Filtrar Clientes Inadimplentes'
              }
              onClick={() => filterClientsDefaulter(!filterClients)}
            />
            <TableComponent
              data={clientsFormatted}
              headerBackground="#245378"
              fileTitle={
                filterClients
                  ? 'Relatório de Clientes Inadimplentes'
                  : 'Relatório de Clientes'
              }
              name="cliente"
              headers={
                filterClients ? [...headers, 'defaulter', 'blockDate'] : headers
              }
              labels={
                filterClients
                  ? [...labels, 'Situação', 'Data do Bloqueio']
                  : labels
              }
              getData={data => history.push(`/client/form/${data.id}`)}
            />
          </Box>
        )}
      </div>
    </ContainerTable>
  );
}

User.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  height: PropTypes.string,
};

User.defaultProps = {
  height: '200px',
};

export default User;
