import PropTypes from 'prop-types';
import React from 'react';
import { MdCheck, MdArrowBack } from 'react-icons/md';

import IconButton from '../buttons/iconButton';
import { Container, ButtonContainer } from './styles';
import history from '../../routes/history'
function headerCardForm({ title, route, loading }) {
  return (
    <Container>
      <div>{title}</div>
      <ButtonContainer>
        <IconButton
          text="Cancelar"
          bg="#C9302C"
          color="#fff"
          type="button"
          onClick={() => history.push(route)}
          Icon={MdArrowBack}
        />
        <IconButton
          text="Salvar"
          bg="#027ab7"
          color="#fff"
          type="subbmit"
          loading={loading}
          Icon={MdCheck}
        />
      </ButtonContainer>
    </Container>
  );
}

headerCardForm.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
};

headerCardForm.defaultProps = {
  loading: false,
};

export default headerCardForm;
