import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import Box from '../../components/BoxFilter';
import Card from '../../components/cards/cardListDevice';
import MaskedInput from '../../components/inputs/maskedInput';
import TableComponent from '../../components/Table';
import history from '../../routes/history';
import { Creators as deviceActions } from '../../store/ducks/deviceSauce';
import { ContainerTable } from './styles';

function Device({ height }) {
  const devices = useSelector(state => state.deviceState.devices);
  const loading = useSelector(state => state.deviceState.loading);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      imei: '',
      phone: '',
    },
    onSubmit: data => {
      const { imei, phone } = data;

      dispatch(deviceActions.fetchFilters(imei, phone));
    },
  });
  useEffect(() => {
    dispatch(deviceActions.fetchDeviceRequest());
  }, [dispatch]);

  const [widthPage, setWidth] = useState(window.innerWidth);

  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <ContainerTable>
      {widthPage <= 700 &&
      <Box columns={2}>
        <form onSubmit={formik.handleSubmit}>
          <MaskedInput
            mask="99999999999999999999999999999999999999999999999999999"
            placeholder="filtrar por imei"
            name="imei"
            onChange={formik.handleChange}
            value={formik.values.imei}
          />
          <MaskedInput
            mask="(99)9 9999-9999"
            placeholder="filtrar por telefone"
            name="phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
          <button type="subbmit">
            <MdSearch size={20} color="#027ab7" />
          </button>
        </form>
      </Box>
      }
      <div style={{ height, overflow: 'scroll' }}>
        {widthPage <= 700 ? (
          <Card
            data={devices.map(item => ({
              ...item,
              formatedModel: `${item.model} - ${item.type}`,
            }))}
            loading={loading}
            changeValue={data => history.push(`/device/form/${data.imei}`)}
          />
        ) : (
          <Box>
            <TableComponent
              loading={loading}
              data={devices.map(item => ({
                ...item,
                formatedModel: `${item.model} - ${item.type}`,
              }))}
              headerBackground="#245378"
              fileTitle="Relatório de dispositivos"
              name="relatório"
              headers={['imei', 'phone', 'formatedModel']}
              labels={['IMEI', 'Linha', 'Modelo']}
              getData={data => history.push(`/device/form/${data.imei}`)}
            />
          </Box>
        )}
      </div>
    </ContainerTable>
  );
}

Device.propTypes = {
  height: PropTypes.string,
};

Device.defaultProps = {
  height: '200px',
};

export default Device;
