import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import history from '../routes/history';
import createRootReducer from './ducks';
import authSaga from './sagas/authSaga';
import clientSaga from './sagas/clientSaga';
import deviceSaga from './sagas/deviceSaga';
import driverPermissionSaga from './sagas/driverPermissionSaga';
import driverSaga from './sagas/driverSaga';
import permissionSaga from './sagas/permissionSaga';
import secretarySaga from './sagas/secretarySaga'
import trackerSaga from './sagas/trackerSaga';
import typeSaga from './sagas/typeSaga';
import userSaga from './sagas/userSaga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const store = createStore(
  createRootReducer(history),
  compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(authSaga);
sagaMiddleware.run(trackerSaga);
sagaMiddleware.run(clientSaga);
sagaMiddleware.run(typeSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(driverSaga);
sagaMiddleware.run(permissionSaga);
sagaMiddleware.run(driverPermissionSaga);
sagaMiddleware.run(deviceSaga);
sagaMiddleware.run(secretarySaga);

export default store;
