import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '../../services/api';
import { Types } from '../ducks/typeSauce';

export function* requestTypeSaga(payload) {
  try {
    const response = yield call(api.fetchType, payload.data);
    if (_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_TYPE_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_TYPE_ID_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({ type: Types.FETCH_TYPE_FAILURE });
  }
}

export function* updateTypeSaga(payload) {
  try {
    yield call(api.updateType, payload.data, payload.id);
    yield put({
      type: Types.FETCH_TYPE_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/type'));
  } catch (error) {
    yield put({ type: Types.FETCH_TYPE_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export function* createTypeSaga(payload) {
  try {
    yield call(api.createType, payload.data);
    yield put({
      type: Types.FETCH_TYPE_REQUEST,
    });
    toast.success('Tipo cadastrado com sucesso!');
    yield put(push('/type'));
  } catch (error) {
    yield put({ type: Types.FETCH_TYPE_FAILURE });
    toast.warning('Erro ao cadastrar o tipo');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_TYPE_REQUEST, requestTypeSaga)]);
  yield all([takeEvery(Types.UPDATE_TYPE_REQUEST, updateTypeSaga)]);
  yield all([takeEvery(Types.CREATE_TYPE_REQUEST, createTypeSaga)]);
}
