import { useFormik } from 'formik';
import React from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';

import SimpleButton from '../../components/buttons/simpleButton';
import MaskedInput from '../../components/inputs/maskedInput';
import SimpleInput from '../../components/inputs/simpleInput';
import { Creators as messageActions } from '../../store/ducks/messageSauce';
import {
  ModalContainer,
  Title,
  Descripition,
  ButtonsContainer,
} from './styles';

export default function TimerModal() {
  const dispatch = useDispatch();
  const visible = useSelector(state => state.messageState.modal === 'server');
  const device = useSelector(state => state.deviceState.device);

  const formik = useFormik({
    initialValues: {
      apn: 'smart.m2m.vivo.com.br',
      user: 'vivo',
      password: 'vivo',
      server: 'gps00.el.com.br',
      port: 4856,
    },
    validate: value => {
      const err = {};
      if (device.model === 'VITANA' || (device.model === 'SUNTECH' && device.type === 'ST4315U')) {
        if (value.server || value.port) {
          if (!value.server) err.server = 'Campo obrigatório!';
          if (!value.port) err.port = 'Campo obrigatório!';
        } else {
          if (!value.apn) err.apn = 'Campo obrigatório!';
          if (!value.user) err.user = 'Campo obrigatório!';
          if (!value.password) err.password = 'Campo obrigatório!';
        }
      } else {
        if (!value.apn) err.apn = 'Campo obrigatório!';
        if (!value.user) err.user = 'Campo obrigatório!';
        if (!value.password) err.password = 'Campo obrigatório!';
        if (!value.server) err.server = 'Campo obrigatório!';
        if (!value.port) err.port = 'Campo obrigatório!';
      }
      return err;
    },
    onSubmit: data => {
      if (device.model === 'SUNTECH' && device.type === 'ST4315U') {
        if (data.server && data.port) {
          const server2port2 = '';
          window.location.href = `sms://${device.phone}?body=PRG;${device.imei};10;05#${data.server};06#${data.port};08#${server2port2};09#${server2port2},`;
        } else if (data.apn && data.user && data.password) {
          window.location.href = `sms://${device.phone}?body=PRG;${device.imei};10;00#01;01#${data.apn};02#${data.user};03#${data.password}`;
        }
      } else if (device.model === 'SUNTECH') {
        window.location.href = `sms://${device.phone}?body=ST300NTW;${device.imei};02;0;${data.apn};${data.user};${data.password};${data.server};${data.port};0;0;0;0`;
      } else if (device.model === 'QUECLINK') {
        window.location.href = `sms://${device.phone}?body=AT+GTSRI=gv75,2,,2,${data.server},${data.port},0.0.0.0,0,,0,0,0,0,,,FFFF$`;
      } else if (device.model === 'VITANA') {
        if (data.server && data.port) {
          window.location.href = `sms://${device.phone}?body=0000,100,1,${data.server},${data.port}`;
        } else if (data.apn && data.user && data.password) {
          window.location.href = `sms://${device.phone}?body=0000,109,1,${data.apn},${data.user},${data.password}`;
        }
      }
    },
  });
  return (
    <Modal
      visible={visible}
      onClickAway={() => dispatch(messageActions.setEnableModal(false))}
      height="400"
      width="300"
      effect="fadeInUp"
    >
      <ModalContainer onSubmit={formik.handleSubmit}>
        <Title>Servidor de rede</Title>
        <Descripition>
          <SimpleInput
            placeholder="Digite a apn"
            name="apn"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.apn}
            error={!!(formik.touched.apn && formik.errors.apn)}
          />
          <SimpleInput
            placeholder="Digite o usuário"
            name="user"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.user}
            error={!!(formik.touched.user && formik.errors.user)}
          />
          <SimpleInput
            placeholder="Digite a senha"
            name="password"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={!!(formik.touched.password && formik.errors.password)}
          />
          <SimpleInput
            placeholder="Digite o servidor"
            name="server"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.server}
            error={!!(formik.touched.server && formik.errors.server)}
          />
          <MaskedInput
            placeholder="Digite a porta"
            mask="9999"
            name="port"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.port}
            error={!!(formik.touched.port && formik.errors.port)}
          />
        </Descripition>
        <ButtonsContainer>
          <SimpleButton
            text="Cancelar"
            bg="#ff4e4e"
            color="#eee"
            onClick={() => dispatch(messageActions.setEnableModal(false))}
          />
          <SimpleButton
            type="subbmit"
            text="Enviar"
            bg="#01659e"
            color="#eee"
          />
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  );
}
