/* eslint-disable no-alert */
import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import React, { useEffect, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import FloatButton from '../../components/buttons/floatButton';
import { Creators as DriverPermissionActions } from '../../store/ducks/driverPermissionSauce';
import { Button, ContainerUser } from './styles';
import 'rc-collapse/assets/index.css';

export default function Permission({ height }) {
  const dispatch = useDispatch();
  const clients = useSelector(state => state.clientState.clients);
  const drivers = useSelector(state => state.driverState.driverClients);
  const permissions = useSelector(
    state => state.driverPermissionState.driverPermissions
  );
  const loading = useSelector(state => state.permissionState.loading);
  const [usersId, setUsersId] = useState([]);
  const formik = useFormik({
    initialValues: {
      cars: [],
      driversArr: [],
      client: {},
    },
    onSubmit: data => {
      permissions.id_drivers = data.driversArr.map(item => ({
        checked: item.checked,
        changed: item.changed,
        id: item.id,
        status: item.status,
      }));
      dispatch(
        DriverPermissionActions.updateDriverPermissionRequest(permissions)
      );
      formik.setFieldValue(
        'driversArr',
        formik.values.driversArr.map(driver => ({
          ...driver,
          defaultChecked: !!driver.checked,
          changed: false,
        }))
      );
    },
  });
  useEffect(() => {
    setUsersId([permissions.user_id]);
    if (!_.isEmpty(drivers) && drivers) {
      const client = clients.find(item => item.id === drivers[0].client_id);
      formik.setValues({
        driversArr: [
          ...drivers.map(driver => ({
            ...driver,
            changed: false,
            defaultChecked: _.isEmpty(permissions)
              ? false
              : !!permissions.id_drivers.includes(driver.id),
            checked: _.isEmpty(permissions)
              ? false
              : !!permissions.id_drivers.includes(driver.id),
          })),
        ],
        client,
      });
    } else {
      formik.setValues({
        driversArr: [],
      });
    }
  }, [drivers, permissions]);

  useEffect(() => {
    const driversArr = [...formik.values.driversArr];
    const driversChanged = driversArr.filter(
      driver => driver.changed || driver.defaultChecked !== driver.checked
    );
    if (driversChanged.length) {
      if (window.confirm('Deseja salvar as alterações?') === true) {
        permissions.id_drivers = driversArr.map(item => ({
          checked: item.checked,
          changed: item.changed,
          id: item.id,
          status: item.status,
        }));
        const userId = usersId[0];
        permissions.user_id = userId;
        dispatch(
          DriverPermissionActions.updateDriverPermissionRequest(permissions)
        );
      }
    }
  }, [permissions]);

  function reverseValues() {
    formik.setFieldValue(
      'driversArr',
      formik.values.driversArr.map(driver => ({
        ...driver,
        checked: !driver.checked,
      }))
    );
  }
  function selectAll() {
    formik.setFieldValue(
      'driversArr',
      formik.values.driversArr.map(driver => ({
        ...driver,
        checked: true,
      }))
    );
  }

  function setChangedValue(index) {
    const { changed } = formik.values.driversArr.find(
      (item, idx) => idx === index
    );
    formik.setFieldValue(`driversArr[${index}].changed`, !changed);
  }

  return (
    <form onSubmit={formik.handleSubmit} style={{ height, overflow: 'scroll' }}>
      <Collapse accordion style={{ height: '100%' }}>
        {!_.isEmpty(formik.values.driversArr) &&
          [formik.values.client].map(client => {
            return (
              <Panel header={client.name} key={client.id}>
                <Button type="button" onClick={() => selectAll()}>
                  Selecionar tudo
                </Button>
                <Button type="button" onClick={() => reverseValues()}>
                  Inverter seleção
                </Button>
                {_.isEmpty(formik.values.driversArr)
                  ? 'Não possui motoristas cadastrados'
                  : formik.values.driversArr.map((driver, indexDriver) => {
                      return (
                        <ContainerUser key={driver.id}>
                          <div className="pretty p-icon p-round p-smooth">
                            <input
                              type="checkbox"
                              id="check"
                              checked={driver.status} // CHECKED
                              onChange={() => {
                                formik.setFieldValue(
                                  `driversArr[${indexDriver}].status`,
                                  !formik.values.driversArr[indexDriver].status
                                );
                                setChangedValue(indexDriver);
                              }}
                              value={
                                formik.values.driversArr[indexDriver].status
                              }
                            />
                            <div className="state p-info">
                              <MdCheck className="icon" />
                              <label htmlFor="check">
                                {driver.status}Ativo
                              </label>
                            </div>
                          </div>
                          <div className="pretty p-icon p-round p-smooth">
                            <input
                              type="checkbox"
                              id="check"
                              checked={driver.checked} // CHECKED
                              onChange={event =>
                                formik.setFieldValue(
                                  `driversArr[${indexDriver}].checked`,
                                  event.target.checked
                                )
                              }
                              value={
                                formik.values.driversArr[indexDriver].checked
                              }
                            />
                            <div className="state p-info">
                              <MdCheck className="icon" />
                              <label htmlFor="check">{driver.name}</label>
                            </div>
                          </div>
                        </ContainerUser>
                      );
                    })}
              </Panel>
            );
          })}
      </Collapse>
      {!_.isEmpty(permissions) && (
        <FloatButton type="subbmit" Icon={MdCheck} loading={loading} />
      )}
    </form>
  );
}

Permission.propTypes = {
  height: PropTypes.string,
};

Permission.defaultProps = {
  height: '200px',
};
