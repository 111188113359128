import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AnimationLoading from './floatAnimationLoading';

const ButtonContainer = styled.div`
  padding: 5px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  @media screen and (max-width: 700px) {
    bottom: 50px;
  }
`;

const FloatButton = styled.button.attrs(props => ({
  type: props.type ? props.type : 'button',
}))`
  width: 50px;
  height: 50px;
  border: none;
  padding: 0 10px;
  border-radius: 500px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.bg ? props.bg : '#0991D1')};
  color: ${props => (props.color ? props.color : '#fff')};

  cursor: ${props => {
    if (props.disabled) {
      return 'not-allowed';
    }
    if (props.loading) {
      return 'progress';
    }
    return 'pointer';
  }};
  :active {
    opacity: 0.5;
  }
`;

function Button({ text, loading, Icon, ...props }) {
  return (
    <ButtonContainer>
      <FloatButton loading={loading} {...props}>
        {loading ? (
          <AnimationLoading />
        ) : (
          <>
            <Icon size={25} /> {text}
          </>
        )}
      </FloatButton>
    </ButtonContainer>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  Icon: PropTypes.elementType.isRequired,
};

Button.defaultProps = {
  loading: false,
};

export default Button;
