import _ from 'lodash';
import React, { useState } from 'react';

import Box from '../../components/Box';
import Container from '../../components/containers/containerSimple';
import SelectClient from '../../components/filters/selectClient';
import TableComponent from '../../components/Table';
import api from '../../services/api';
import { Header } from './styles';

export default function Permission() {
  const [trackers, setTrackers] = useState([]);
  async function changeValues(options) {
    try {
      const { data } = await api.fetchReportTrackerByClient(options.value);
      setTrackers(data);
    } catch (error) {
      setTrackers([]);
    }
  }
  return (
    <Container permission>
      <Header permission>
        <div>Relatorio de item de rastreamento por cliente</div>
        <SelectClient changeClientValue={option => changeValues(option)} />
      </Header>
      <Box>
        <TableComponent
          data={
            !_.isEmpty(trackers)
              ? trackers.filter(tracker => tracker.device_id !== '')
              : []
          }
          fileTitle="Relatório de item de rastreamento por cliente"
          name="item de rastreamento"
          headers={['plate', 'year', 'device_id', 'brand', 'model']}
          labels={['Placa', 'Ano', 'IMEI', 'Marca', 'Modelo']}
          getData={() => console.log('tracker')}
        />
      </Box>
    </Container>
  );
}
