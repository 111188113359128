import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '../../services/api';
import { Types } from '../ducks/permissionSauce';

export function* requestPermissionSaga(payload) {
  try {
    const response = yield call(api.fetchPermission, payload.data);
    yield put({
      type: Types.FETCH_PERMISSION_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_PERMISSION_FAILURE });
  }
}

export function* updatePermissionSaga(payload) {
  try {
    yield call(api.updatePermission, payload.data);
    yield put({
      type: Types.UPDATE_PERMISSION_SUCCESS,
    });
    toast.success('Atualizado com sucesso!');
  } catch (error) {
    yield put({ type: Types.FETCH_PERMISSION_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_PERMISSION_REQUEST, requestPermissionSaga)]);
  yield all([takeEvery(Types.UPDATE_PERMISSION_REQUEST, updatePermissionSaga)]);
}
