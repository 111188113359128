import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

export const Scroll = styled(PerfectScrollbar)`
  /* height: calc(100% - 170px); */
`;

export const Container = styled.div`
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  display: grid;
  height: 80px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'image name name' 'image name name';
`;

export const ImageCar = styled.img`
  grid-area: image;
  height: 35px;
  margin: auto;
`;

export const Description = styled.div`
  grid-area: detail;
  text-transform: uppercase;
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Title = styled.div`
  grid-area: name;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: start;
`;
