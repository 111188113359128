import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as ClientActions } from '../../store/ducks/clientSauce';
import SelectField from './selectField';

function SelectClientField({ changeClientValue, error, defaultValue }) {
  const loading = useSelector(state => state.clientState.loading);
  const clients = useSelector(state =>
    state.clientState.clients
      .map(client => ({
        label: client.name,
        value: client.id,
      }))
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      })
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ClientActions.fetchClientRequest());
  }, [dispatch]);

  return (
    <SelectField
      options={clients}
      loading={loading}
      defaultValue={
        defaultValue.label === ''
          ? clients.find(
              ({ value }) =>
                Number(value) === Number(localStorage.getItem('clientId'))
            )
          : defaultValue
      }
      title="Cliente"
      changeValueSelect={option => changeClientValue(option)}
      placeholder="Selecione o cliente"
      error={error}
    />
  );
}

SelectClientField.propTypes = {
  changeClientValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.bool,
};

SelectClientField.defaultProps = {
  defaultValue: {},
  error: false,
};

export default SelectClientField;
